import React from 'react'
import { Checkbox, Space } from 'antd'
import PropTypes from 'prop-types'

/*
 * Features
 * @param {Object} features - The features object.
 * @param {Function} setFeatures - The function to set the features object.
 * @returns {JSX.Element} The features component.
 * Used to toggle features on and off. Returns a checkbox for each feature.
 * Could be used to controll what features are shown in a form. Controll what columns to be shown in a table or list.
 */
const Features = ({ features, setFeatures }) => {
	if (!features) return null

	return (
		<Space id="target-features">
			{Object.keys(features).map((feature) => {
				return (
					<div key={feature} id={'target-features-' + feature}>
						<Checkbox
							checked={features[feature]}
							disabled={!setFeatures}
							onChange={(e) =>
								setFeatures &&
								setFeatures({
									...features,
									[feature]: e.target.checked
								})
							}
						>
							{feature}
						</Checkbox>
					</div>
				)
			})}
		</Space>
	)
}
Features.propTypes = {
	features: PropTypes.object.isRequired,
	setFeatures: PropTypes.func
}

export default Features

/** 
* Features
* Example use: 
	const [features, setFeatures] = React.useState({
		recurrence: _itm.recurrenceType !== 'deadline',
		subtasks: toJS(_itm.getSubtasks)?.length > 0,
		comments: toJS(_itm.getComments)?.length > 0
	})

	const onFeaturesChange = (features) => {
		if (!features.recurrence) {
			form.setFieldsValue({
				recurrenceType: 'deadline',
				recurrenceStartEnd: [],
				recurrenceMonthsTrack: [],
				recurrenceDaysOfWeek: [],
				recurrenceDaysOfMonth: [],
				recurrenceMonths: [],
				recurrenceTimes: []
			})
		}
		if (!features.subtasks) {
			form.setFieldsValue({
				subtasks: []
			})
		}
		if (!features.comments) {
			form.setFieldsValue({
				comments: []
			})
		}
	}
	<Features
		features={features}
		setFeatures={(val) => {
			setFeatures(val)
			onFeaturesChange(val)
		}}
	/>
*/
