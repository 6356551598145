import { makeObservable, observable, computed, action, toJS } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

import { Comments } from './comments'

/**
 * Class representing a Subtask.
 */
class Subtask {
	id
	name
	description
	status
	createdTs
	createdBy
	comments

	constructor({
		id,
		name,
		description,
		status,
		createdBy,
		createdTs,
		comments = []
	}) {
		this.id = id || uuidv4()
		this.name = name
		this.description = description
		this.status = status
		this.createdBy = createdBy
		this.createdTs = createdTs || dayjs().unix()
		this.comments = new Comments(toJS(comments))

		makeObservable(this, {
			name: observable,
			description: observable,
			status: observable,
			createdTs: observable,
			createdBy: observable,
			comments: observable,

			addComment: action.bound,
			getCommentById: action.bound,
			deleteComment: action.bound,
			setStatus: action.bound
		})
	}

	addComment(description, images, createdBy) {
		this.comments.addComment(description, images, createdBy)
	}

	getCommentById(commentId) {
		return this.comments.getCommentById(commentId)
	}

	deleteComment(commentId) {
		this.comments.deleteComment(commentId)
	}

	setStatus(status) {
		this.status = status
	}
}

/**
 * Class representing a collection of Subtasks.
 */
export class Subtasks {
	list = []

	constructor(list = []) {
		this.list = list.map((subtask) => new Subtask(subtask))
		makeObservable(this, {
			list: observable,
			addSubtask: action.bound,
			deleteSubtask: action.bound,
			getSubtaskById: action.bound,
			subtaskCount: computed
		})
	}

	prepareForSave() {
		if (!Array.isArray(this.list)) {
			return [] // Ensure the function returns an empty array if this.list is not an array
		}

		return this.list.map((subtask) => {
			// Ensure subtask.comments.list is an array before mapping
			const comments = Array.isArray(subtask.comments.list)
				? subtask.comments.list.map((comment) => comment)
				: [] // Return an empty array if subtask.comments.list is not an array

			return {
				...subtask,
				comments
			}
		})
	}

	updateSubtask(subtask) {
		const index = this.list.findIndex((s) => s.id === subtask.id)
		if (index >= 0) {
			this.list[index] = subtask
		}
	}

	addSubtask(name, description, status, createdBy) {
		const subtask = new Subtask({ name, description, status, createdBy })
		this.list.push(subtask)
	}

	deleteSubtask(subtaskId) {
		this.list = this.list.filter((subtask) => subtask.id !== subtaskId)
	}

	getSubtaskById(subtaskId) {
		return this.list.find((subtask) => subtask.id === subtaskId)
	}

	get subtaskCount() {
		return this.list.length
	}
}
