import { addGETParams } from '../config'
import ax from './api'
/*
* setRequest - Set a sprint item on the server
* @param {string} sprintId - The id of the sprint item
* @param {object} full - The full object of the sprint item
* @returns {object} The response from the server

*/
export const setRequest = async (requestId, full) => {
	// Convert full object to JSON string
	const fullStr = JSON.stringify(full)
	const _url = addGETParams(`/request/set.php`, {
		request_id: requestId,
		full: fullStr
	})

	return await ax.post(_url)
}
