import { makeAutoObservable, runInAction } from 'mobx'
import User from '../../modules/cls/user'
import { LOGIN_API_TOKEN_EXPIRATION } from '../../config'

/**
 * @class UsersStore
 * @description This class represents the store for user management. It manages the state of users, authentication, and token expiration.
 */
class UsersStore {
	/**
	 * @property {Object} users - An object containing users indexed by their IDs.
	 * @property {string|null} loggedInUserId - The ID of the currently logged-in user.
	 * @property {Object|null} apiToken - The API token used for authentication.
	 * @property {string} tokenCountdown - A string representing the countdown for the token expiration.
	 */
	users = {}
	loggedInUserId = null
	apiToken = null
	redirectOnLogin = ''
	tokenCountdown = ''

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of UsersStore, makes it observable, loads initial state, and checks token expiration.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)

		// Load initial state from localStorage
		this.loadState()

		// Check for token expiration on initialization
		this.checkTokenExpiration()
	}

	/**
	 * @computed
	 * @description Checks if a user is logged in and the token is valid (not expired).
	 * @returns {boolean} True if a user is logged in and the token is valid, false otherwise.
	 */
	get isLoggedIn() {
		if (!this.loggedInUserId || !this.apiToken) {
			return false
		}
		// Check if the token is expired (24 hours)
		const currentTime = Math.floor(Date.now() / 1000)
		return (
			currentTime - this.apiToken.timestamp <= LOGIN_API_TOKEN_EXPIRATION
		)
	}

	/**
	 * @action
	 * @description Sets the logged-in user and API token, and saves the state.
	 * @param {string} userId - The ID of the logged-in user.
	 * @param {string} apiToken - The API token for the logged-in user.
	 */
	setLoggedInUser(userId, apiToken) {
		runInAction(() => {
			this.loggedInUserId = userId
			this.apiToken = JSON.parse(apiToken)
			this.saveState()
		})
		this.scheduleTokenExpirationCheck()
	}

	/**
	 * @action
	 * @description Clears the logged-in user and API token, and saves the state.
	 */
	clearLoggedInUser() {
		runInAction(() => {
			this.loggedInUserId = null
			this.apiToken = null
			this.saveState()
		})
	}

	/**
	 * @action
	 * @description Sets the redirect URL after login. Used when error or expiration occurs, when forced to logout, we define curent url to redirect after login.
	 * @param {string} redirectOnLogin - The URL to redirect to after login.
	 * @returns {void}
	 */
	setRedirectOnLogin(redirectOnLogin) {
		if (this.redirectOnLogin) return // if already set, do nothing
		runInAction(() => {
			this.redirectOnLogin = redirectOnLogin
			this.saveState()
		})
	}

	// When login is successful, we will clear the redirectOnLogin value
	clearRedirectOnLogin() {
		runInAction(() => {
			this.redirectOnLogin = ''
			this.saveState()
		})
	}

	/**
	 * @action
	 * @description Retrieves a user by their ID from the store. If the user doesn't exist, creates a new user.
	 * @param {string} userId - The ID of the user to retrieve.
	 * @returns {User} The retrieved or newly created user.
	 */
	requireUserById(userId) {
		runInAction(() => {
			if (!this.users[userId]) {
				this.users[userId] = new User(userId)
			}
		})

		return this.users[userId]
	}

	get loggedInUser() {
		return this.users[this.loggedInUserId] || {}
	}

	get availableUserIds() {
		return Object.keys(this.users)
	}

	/**
	 * @async
	 * @action
	 * @description Logs out the currently logged-in user.
	 * @returns {Promise<void>}
	 */
	async logout(redirectOnLogin) {
		this.setRedirectOnLogin(redirectOnLogin)
		// Clear the logged-in user and API token
		this.clearLoggedInUser()
	}

	/**
	 * @action
	 * @description Schedules a check for token expiration.
	 */
	scheduleTokenExpirationCheck() {
		if (!this.apiToken) return
		const expireTime =
			LOGIN_API_TOKEN_EXPIRATION -
			(Date.now() / 1000 - this.apiToken.timestamp)
		setTimeout(() => this.checkTokenExpiration(), expireTime * 1000)
	}

	/**
	 * @action
	 * @description Checks if the token is expired and logs out the user if it is.
	 */
	checkTokenExpiration() {
		const apiToken = JSON.parse(localStorage.getItem('apiToken'))
		if (
			apiToken &&
			Date.now() / 1000 - apiToken.timestamp > LOGIN_API_TOKEN_EXPIRATION
		) {
			this.logout()
		} else if (apiToken) {
			this.scheduleTokenExpirationCheck()
		}
	}

	/**
	 * @action
	 * @description Saves the current state to localStorage.
	 */
	saveState() {
		const state = {
			redirectOnLogin: this.redirectOnLogin,
			loggedInUserId: this.loggedInUserId,
			apiToken: this.apiToken ? JSON.stringify(this.apiToken) : null,
			users: this.serializeUsers()
		}
		localStorage.setItem('usersStore', JSON.stringify(state))
	}

	/**
	 * @action
	 * @description Loads the state from localStorage.
	 */
	loadState() {
		const state = JSON.parse(localStorage.getItem('usersStore'))
		if (state) {
			runInAction(() => {
				this.loggedInUserId = state.loggedInUserId
				this.apiToken = state.apiToken
					? JSON.parse(state.apiToken)
					: null
				this.users = this.deserializeUsers(state.users)
			})
		}
	}

	/**
	 * @description Serializes the users in the store to a JSON-friendly format.
	 * @returns {Object} The serialized users.
	 */
	serializeUsers() {
		const serializedUsers = {}
		for (const userId in this.users) {
			serializedUsers[userId] = this.users[userId].toJSON()
		}
		return serializedUsers
	}

	/**
	 * @description Deserializes users from a JSON-friendly format.
	 * @param {Object} users - The users to deserialize.
	 * @returns {Object} The deserialized users.
	 */
	deserializeUsers(users) {
		const deserializedUsers = {}
		for (const userId in users) {
			const localUser = users[userId]

			// if localUser.full is array, convert to object
			if (localUser.full && Array.isArray(localUser.full)) {
				localUser.full = localUser.full.reduce((acc, item) => {
					acc[item[0]] = item[1]
					return acc
				}, {})
			}

			const user = new User(userId)
			user.updateFromServer(localUser)
			deserializedUsers[userId] = user
		}
		return deserializedUsers
	}

	/**
	 * @action
	 * @description Starts the countdown for the token expiration.
	 */
	// not used
	startTokenCountdown() {
		if (this.countdownInterval) clearInterval(this.countdownInterval)
		if (!this.apiToken?.timestamp) return

		this.countdownInterval = setInterval(() => {
			if (!this.apiToken.timestamp) return
			const currentTime = Math.floor(Date.now() / 1000)
			const remainingTime =
				LOGIN_API_TOKEN_EXPIRATION -
				(currentTime - this.apiToken.timestamp)
			if (remainingTime <= 0) {
				this.tokenCountdown = ''
				clearInterval(this.countdownInterval)
			} else {
				const hours = Math.floor(remainingTime / 3600)
				const minutes = Math.floor((remainingTime % 3600) / 60)
				const seconds = remainingTime % 60
				this.tokenCountdown = `${hours}h:${minutes}m:${seconds}s`
			}
		}, 1000)
	}
}

export default UsersStore
