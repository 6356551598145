import React, { useEffect } from 'react'
import { Button, Modal, Form, Input, Select, Row, Col } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { get } from 'lodash'

import {
	actLoadRequest,
	actSaveRequest,
	requireRequestById
} from '../../stores/requests/actions'
import { DO_LOAD, IS_NEW } from '../../config'
import { useStores } from '../../stores/MobXProvider'
import { runInAction, toJS } from 'mobx'
import { actLoadRequests } from '../../stores/ui-lst-requests/actions'
import { CommentsInput } from '../../components/Form'
import { UserString } from '../../components/Shared'

// TODO, observe visible change
const RequestModal = observer(() => {
	const [form] = Form.useForm()
	const { users, uiLstRequests } = useStores()
	const { loggedInUser } = users

	const { visible, itmId } = get(uiLstRequests, ['modals', 'request-modal'], {
		visible: false,
		itmId: null
	})

	const _itm = itmId && requireRequestById(itmId)
	// console.log('RequestModal itm', toJS(_itm))

	useEffect(() => {
		if (itmId && _itm.loaded === DO_LOAD) {
			actLoadRequest(itmId).catch(console.error)
		}
	}, [itmId, visible])

	useEffect(() => {
		if (_itm) {
			form.setFieldsValue({
				createdBy:
					_itm.loaded === IS_NEW
						? loggedInUser.user_id
						: _itm.createdBy,
				name: _itm?.name,
				forUserId: _itm?.forUserId,
				comments: toJS(_itm?.getComments || [])
			})
			// console with curent form values
			console.log('form.getFieldsValue', form.getFieldsValue())
		}
	}, [itmId, _itm?.loaded])
	// Use useWatch to get the current value of "createdBy"
	const createdBy = Form.useWatch('createdBy', form)

	if (!itmId || !loggedInUser?.user_id) return null
	const onSave = (vals) => {
		if (vals !== null) {
			runInAction(() => {
				// Set creator and timestamp
				_itm.setCreator(vals.createdBy)
				// Reset last full load
				_itm.loaded === IS_NEW && _itm.resetLastFullLoad()

				// Form values
				_itm.setName(vals?.name)
				_itm.setForUserId(vals.forUserId)

				// Comments
				_itm.setComments(vals.comments)
			})
			actSaveRequest(itmId, toJS(_itm.full))
				.then((response) => {
					console.log('actSaveRequest response', response)
					runInAction(() => {
						delete uiLstRequests.modals['request-modal']
					})

					/*
					 * Refresh the requests list with server data
					 * - Requests might come with extra data that needs to be updated in adiacent stores
					 */
					actLoadRequests().catch(console.error)
				})
				.catch(console.error)
		}
	}
	return (
		<Modal
			title={_itm.loaded !== IS_NEW ? 'Edit Request' : 'Add Request'}
			open={visible}
			onCancel={() => {
				runInAction(() => {
					delete uiLstRequests.modals['request-modal']
				})
			}}
			footer={null}
		>
			<Form
				form={form}
				style={{ width: '100%', marginTop: 25 }}
				layout="vertical"
				onFinish={onSave}
			>
				<Row style={{ width: '100%' }}>
					<Col span={12}>
						<Form.Item
							label="By user"
							tooltip="Is used to identify the requester."
							name="createdBy"
						>
							<UserString
								userId={createdBy ?? loggedInUser.user_id}
								path={['name']}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="For user"
							tooltip="Send a request to a specific user"
							name="forUserId"
							disabled={_itm.loaded !== IS_NEW}
							rules={[
								{
									required: true,
									message: 'Please select a user!'
								}
							]}
						>
							<Select
								disabled={_itm.loaded !== IS_NEW}
								placeholder="Select a user"
								allowClear
								options={[
									// Optionally include the logged-in user at the beginning
									loggedInUser.user_id,
									// Combine clients and team arrays, filter duplicates and exclude the logged-in user
									...[
										...new Set([
											...loggedInUser.clients,
											...loggedInUser.team
										])
									].filter(
										(user_id) =>
											user_id !== loggedInUser.user_id
									)
								].map((user_id) => {
									return {
										label: (
											<>
												{user_id ===
													loggedInUser.user_id && (
													<>
														<UserOutlined />{' '}
													</>
												)}
												<UserString
													userId={user_id}
													path={['name']}
												/>
												{user_id ===
												loggedInUser.user_id
													? ' (self request)'
													: ''}
											</>
										),
										value: user_id
									}
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<hr></hr>
				<Form.Item
					label="Name"
					name="name"
					tooltip="The name of the _itm."
					rules={[
						{
							required: true,
							message: 'Please enter a name!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Comments"
					name="comments"
					tooltip="Comments can be added to the sprint."
				>
					<CommentsInput createdBy={loggedInUser.user_id} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						{_itm.loaded !== IS_NEW ? 'Update' : 'Add'}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
})

export default RequestModal
