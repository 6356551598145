import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
/*
 * Component that logs out the user and redirects to the login page.
 * @param {Object} rootStore - The root store.
 * @returns {null} Null.
 * */
const Logout = ({ rootStore }) => {
	const navigate = useNavigate()

	useEffect(() => {
		rootStore.users.logout()
		navigate('/login') // Redirect to login page after logout
	}, [rootStore, navigate])

	return null
}
Logout.propTypes = {
	rootStore: PropTypes.object
}

export default Logout
