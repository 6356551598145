import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Space, ConfigProvider, Form, Input, Card, Tooltip } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { runInAction, toJS } from 'mobx'
import { IS_NEW } from '../../config'
import { actSaveUser } from '../../stores/users/actions'
import { useStores } from '../../stores/MobXProvider'
import { GeneralTitle } from '../../components/Shared/GeneralComponents'
import LstTypeInputBase from '../../components/Form/LstTypeInputBase'
import { Platforms } from '../../modules/cls/platforms'
import DynamicFormComponent from '../../components/Form/DynamicFormComponent'
import { SelectUsers } from '../../components/Form/SelectUsers'

/**
 * Profile component allows viewing and editing of user profile information.
 * It handles both new user creation and existing user profile updates.
 *
 * @component
 * @returns {JSX.Element} The rendered Profile component.
 */
const UserData = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm()

	// Get user ID from URL params
	const { itmId } = useParams()
	const { users } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const user = users.requireUserById(itmId)

	let _initialFormValues = {}
	useEffect(() => {
		_initialFormValues = {
			name: user.name,
			platforms: user.platforms,
			clients: user.clients,
			team: user.team
		}
		form.setFieldsValue(_initialFormValues)
	}, [itmId])

	// console.log('--- Profile', user, toJS(user.platforms))

	/**
	 * Handles form submission for saving user profile.
	 *
	 * @param {Object} vals - The form values.
	 */
	const _doSave = (vals) => {
		if (vals !== null) {
			// console.log('--- Save', vals)
			runInAction(() => {
				// Reset last full load
				user.loaded === IS_NEW && user.resetLastFullLoad()
				user.setName(vals.name)
				user.setPlatforms(vals.platforms)
				user.setClients(vals.clients)
				user.setTeam(vals.team)
			})
			actSaveUser(user.user_id, toJS(user.full)).then(() => {
				users.saveState()
				navigate('/dashboard')
			})
		}
	}

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<GeneralTitle text="Profile" />
			<Card
				title={
					user.loaded === IS_NEW ? (
						'Create new user'
					) : (
						<Tooltip title="Edit user">
							<SettingOutlined /> {user?.name}
						</Tooltip>
					)
				}
				style={{ width: '100%' }}
			>
				<ConfigProvider>
					<Form
						form={form}
						name="profile"
						initialValues={_initialFormValues}
						onFinish={_doSave}
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						style={{ maxWidth: 600 }}
					>
						<Form.Item
							label="Email"
							tooltip="Is used for searching, filtering and assignment."
						>
							{user.email}
						</Form.Item>
						<Form.Item
							label="Name"
							tooltip="Is used for searching and filtering."
							name="name"
							rules={[
								{
									required: true,
									message: 'Please provide a name!'
								}
							]}
						>
							<Input />
						</Form.Item>
						{isAdmin && (
							<>
								<Form.Item
									label="Clients"
									tooltip="Clients & coresponding data assigned to curent user. Multiple clients can be selected."
									name="clients"
								>
									<SelectUsers
										type="clients"
										mode="multiple"
										style={{ width: '100%' }}
									/>
								</Form.Item>

								<Form.Item
									label="Team"
									tooltip="Team members assigned to curent user. Multiple team members can be selected."
									name="team"
								>
									<SelectUsers
										type="team"
										mode="multiple"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</>
						)}

						<Form.Item
							label="Platforms"
							tooltip="Sprints will require a platform to be assigned. Multiple platforms can be selected."
							name="platforms"
						>
							<LstTypeInputBase
								UseLstClass={Platforms}
								phaseTwoLimited
								additionalEditSection={() => {
									/*
									 * Additional section for dynamic form in EditItem Modal
									 */
									return (
										<Form.Item
											name="data"
											wrapperCol={{ span: 24 }}
										>
											<DynamicFormComponent />
										</Form.Item>
									)
								}}
							/>
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type="primary" htmlType="submit">
								{user.loaded === IS_NEW ? 'Create' : 'Update'}
							</Button>
						</Form.Item>
					</Form>
				</ConfigProvider>
			</Card>
		</Space>
	)
}

export default observer(UserData)
