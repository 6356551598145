import React, { useState } from 'react'
import {
	Button,
	List,
	Modal,
	Form,
	Input,
	Tooltip,
	Tag,
	Space,
	Row,
	Col,
	Upload,
	Image
} from 'antd'
import { UserOutlined, PlusOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { Comment, Comments } from '../../modules/cls/comments'
import { useStores } from '../../stores/MobXProvider'
import { FormatedTimestamp, UserString } from '../Shared'
import { v4 as uuidv4 } from 'uuid'
import { uploadImage } from '../../modules/api-images'
import { API_BASE_URL } from '../../config'
import { useParams } from 'react-router-dom'

const { TextArea } = Input

const EditComment = observer(({ comment, onSave, onCancel }) => {
	const { itmId } = useParams()
	const { users } = useStores()
	const { loggedInUserId } = users

	const [isModalVisible, setIsModalVisible] = useState(true)
	const editingComment =
		comment ||
		new Comment({
			description: '',
			images: [],
			createdBy: loggedInUserId
		})

	const [fileList, setFileList] = useState(
		editingComment.images.map((image) => ({
			uid: image,
			name: image,
			status: 'done',
			url: `${API_BASE_URL}/images/uploads/${image}`
		})) || []
	)

	const uploadProps = {
		beforeUpload: async (file) => {
			const formData = new FormData() // Make sure formData is initialized here
			const imageUuid = uuidv4() // Generate a unique ID for the image
			const fileExtension = file.name.split('.').pop() // Extract the file extension
			const newFileName = itmId
				? `${itmId}__${imageUuid}.${fileExtension}` // Combine item ID, UUID and extension for the new filename
				: `${imageUuid}.${fileExtension}` // Combine UUID and extension for the new filename

			formData.append('image', file) // Add the file to formData
			formData.append('newFileName', newFileName) // Add the generated filename to formData

			uploadImage(formData)
				.then((response) => {
					// Check if the upload was successful
					if (response.data.status === 'success') {
						// Add the new image filename to the fileList
						setFileList([
							...fileList,
							{
								uid: newFileName,
								name: file.name,
								status: 'done',
								url: `${API_BASE_URL}/images/uploads/${newFileName}`
							}
						])

						editingComment.addImage(newFileName) // Add the image to the comment's images array
						console.warn(`${file.name} uploaded successfully.`)
					} else {
						console.error(`${file.name} upload failed.`)
					}
				})
				.catch((error) => console.error(`Upload error: ${error}`))

			// Prevent automatic upload
			return false
		},
		onRemove: (file) => {
			// Remove image from comment and fileList
			editingComment.removeImage(file.uid)
			setFileList(fileList.filter((item) => item.uid !== file.uid))
		},
		fileList,
		listType: 'picture'
	}

	return (
		<Modal
			title={editingComment ? 'Edit Comment' : 'Add Comment'}
			open={isModalVisible}
			onCancel={() => {
				setIsModalVisible(false)
				onCancel()
			}}
			footer={null}
		>
			<Form
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={editingComment || {}}
				onFinish={(values) => {
					const completeValues = values
					completeValues.images =
						fileList.map((file) => file.uid) || []
					onSave(completeValues)
				}}
			>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{ required: true, message: 'Please enter description' }
					]}
				>
					<TextArea rows={4} showCount maxLength={200} />
				</Form.Item>

				<Form.Item label="Upload Images">
					<Upload {...uploadProps}>
						<Button icon={<PlusOutlined />}>Upload</Button>
					</Upload>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						{editingComment ? 'Update' : 'Add'}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
})

const CommentsInput = observer(({ createdBy, value, onChange, ...props }) => {
	const { users } = useStores()
	const { loggedInUserId, loggedInUser } = users
	const isSuperAdmin = loggedInUser.isSuperAdmin

	const { disabled } = props
	// console.log('CommentsInput -> value', value)
	const comments = new Comments(value || [])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [editingComment, setEditingComment] = useState(null)

	const showModal = () => {
		setEditingComment(null)
		setIsModalVisible(true)
	}

	const save = () => {
		onChange(comments.prepareForSave())
	}

	const onSaveComment = (values) => {
		if (editingComment) {
			editingComment.description = values.description
			comments.updateComment(editingComment)
		} else {
			comments.addComment(values.description, values.images, createdBy)
		}
		save()
		setIsModalVisible(false)
	}

	const handleEdit = (commentId) => {
		const comment = comments.getCommentById(commentId)
		if (isSuperAdmin || loggedInUserId === comment.createdBy) {
			setEditingComment(comment)
			setIsModalVisible(true)
		} else {
			alert('You are not allowed to edit this comment')
		}
	}

	const handleDelete = (commentId) => {
		const comment = comments.getCommentById(commentId)
		if (isSuperAdmin || loggedInUserId === comment.createdBy) {
			comments.deleteComment(commentId)
			save()
		} else {
			alert('You are not allowed to delete this comment')
		}
	}

	return (
		<>
			<List
				header={
					!disabled && (
						<Button type="dashed" onClick={showModal}>
							Add Comment
						</Button>
					)
				}
				bordered
				dataSource={comments.list}
				renderItem={(comment) => (
					<List.Item
						actions={
							!disabled &&
							(isSuperAdmin ||
								loggedInUserId === comment.createdBy)
								? [
										<Button
											key={comment.id + 'edit'}
											type="link"
											onClick={() =>
												handleEdit(comment.id)
											}
										>
											Edit
										</Button>,
										<Button
											key={comment.id + 'delete'}
											type="link"
											onClick={() =>
												handleDelete(comment.id)
											}
										>
											Delete
										</Button>
								  ]
								: []
						}
					>
						<Space direction="vertical">
							<Row gutter={5} align="middle">
								<Col>
									{loggedInUserId === comment.createdBy ? (
										<Tooltip title="Self comment">
											<Tag color="warning">
												<UserOutlined />
											</Tag>
										</Tooltip>
									) : (
										<Tooltip title="Comment by">
											<Tag color="default">
												<UserString
													userId={comment.createdBy}
													path="name"
												/>
											</Tag>
										</Tooltip>
									)}
								</Col>
								<Col
									style={{
										color: 'grey',
										fontSize: '12px'
									}}
								>
									<Tooltip title="Created on">
										<span>
											<FormatedTimestamp
												timestamp={comment.createdTs}
												formatedAs="DD.MM.YYYY hh:mm"
											/>
										</span>
									</Tooltip>
								</Col>
							</Row>
							<div>{comment.description}</div>
							{comment?.images.length > 0 && (
								<div>
									{comment?.images &&
										comment?.images.map((imagePath) => (
											<Image
												key={imagePath}
												width={100}
												src={`${API_BASE_URL}/images/uploads/${imagePath}`}
											/>
										))}
								</div>
							)}
						</Space>
					</List.Item>
				)}
			/>
			{isModalVisible && (
				<EditComment
					comment={editingComment}
					onSave={onSaveComment}
					onCancel={() => setIsModalVisible(false)}
				/>
			)}
		</>
	)
})

export default CommentsInput
