import { makeAutoObservable, runInAction } from 'mobx'

/**
 * @class SubscriptionsStore
 * @description This class represents the store for subscriptions. It manages the state of subscriptions and is part of the root store.
 */
class SubscriptionsStore {
	/**
	 * @property plans - An array of subscription plans.
	 */
	plans = []

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of SubscriptionsStore and makes it observable.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)
		// Load initial state from localStorage
		this.loadState()
	}

	filterPlansByCategory(category) {
		return plans.filter((plan) => plan.product.metadata.type === category)
	}

	setPlans(plans) {
		this.plans = plans
		this.saveState()
	}

	/**
	 * @action
	 * @description Saves the current state to localStorage.
	 */
	saveState() {
		const state = {
			plans: this.serializePlans()
		}
		localStorage.setItem('subscriptionsStore', JSON.stringify(state))
	}

	/**
	 * @action
	 * @description Loads the state from localStorage.
	 */
	loadState() {
		const state = JSON.parse(localStorage.getItem('subscriptionsStore'))
		if (state) {
			runInAction(() => {
				this.plans = this.deserializePlans(state.plans)
			})
		}
	}

	/**
	 * @description Deserializes plans from a JSON-friendly format.
	 * @param {Object} plans - The plans to deserialize.
	 * @returns {Object} The deserialized plans.
	 */
	deserializePlans(plans) {
		const deserializedPlans = []
		for (const plan of plans) {
			deserializedPlans.push({
				...plan
			})
		}
		return deserializedPlans
	}
	/**
	 * @description Serializes the plans in the store to a JSON-friendly format.
	 * @returns {Object} The serialized plans.
	 */
	serializePlans() {
		return this.plans.map((plan) => {
			return {
				...plan
			}
		})
	}
}

export default SubscriptionsStore
