import React from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import _ from 'lodash'

const OptionsSelector = ({ options, value, onChange, ...props }) => {
	const {
		style,
		className,
		placeholder,
		listHeight,
		listItemHeight,
		dropdownStyle,
		sort = false,
		dropdownMinWidth = false,
		...rest
	} = props

	const sortedOptions = _.orderBy(options, (o) => {
		if (typeof o.label === 'string') {
			// Defined locally such as placeholder, or extra options
			return o.label.toLowerCase()
		}
		if (typeof o.label === 'object') {
			return o.label.props.children.toLowerCase()
		}

		return o.label
	})

	const minWidth = dropdownMinWidth
		? { minWidth: dropdownMinWidth }
		: { minWidth: 'min-content' }

	return (
		<Select
			allowClear
			options={sort ? sortedOptions : options}
			value={value}
			onChange={onChange}
			placement="bottomLeft"
			placeholder={placeholder ?? '--'}
			className={className ?? 'antd-select-style-1 '}
			listHeight={listHeight ?? 150}
			listItemHeight={listItemHeight ?? 10}
			style={{
				...{ width: '100%', display: 'inline-block' },
				...style
			}}
			dropdownStyle={{ ...minWidth, ...dropdownStyle }}
			{...rest}
		/>
	)
}

OptionsSelector.propTypes = {
	options: PropTypes.array,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.array,
		PropTypes.number
	]),
	onChange: PropTypes.func,
	style: PropTypes.object,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	listHeight: PropTypes.number,
	listItemHeight: PropTypes.number,
	dropdownStyle: PropTypes.object,
	dropdownMinWidth: PropTypes.string,
	sort: PropTypes.bool
}

export default observer(OptionsSelector)
