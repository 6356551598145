import React, { useEffect, useState } from 'react'
import { Spin, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../stores/MobXProvider'
import { toJS } from 'mobx'
import SubscriptionOptions from '../components/Stripe/SubscriptionOptions'
const { Title } = Typography

const Subscriptions = () => {
	const navigate = useNavigate()
	const [loadingItm, setLoadingItm] = useState(true)

	const { users } = useStores()
	const { loggedInUserId } = users
	const user = users.requireUserById(loggedInUserId)

	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}

		setLoadingItm(false)
	}, [loggedInUserId])

	if (loadingItm) {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>Loading...</p>
				<Spin />
			</div>
		)
	}

	console.log('Loggedin user:', toJS(user.allowed))

	return (
		<>
			{loggedInUserId && (
				<>
					<Title
						level={4}
						style={{ fontSize: 25, marginTop: 0, marginBottom: 10 }}
					>
						Subscriptions:
					</Title>

					<SubscriptionOptions />
				</>
			)}
		</>
	)
}

export default observer(Subscriptions)
