import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { runInAction } from 'mobx'
import { Card, Button, Typography, Tabs, Row, Col, Space } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

import { useStores } from '../../stores/MobXProvider'
import ActiveSubscriptions from './ActiveSubscriptions'
import {
	stripeCheckout,
	stripeLoadPlans
} from '../../stores/subscriptions/actions'
import { observer } from 'mobx-react-lite'

const { Title } = Typography

const SubscriptionOptions = () => {
	const { users, subscriptions } = useStores()
	const plans = subscriptions.plans

	const { loggedInUserId } = users
	const user = users.requireUserById(loggedInUserId)
	const userEmail = user.email

	useEffect(() => {
		// Fetch plans if not already loaded
		plans.length === 0 && stripeLoadPlans()
	}, [])

	// Design
	const renderPlanDetails = (plan) => (
		<Card key={plan.id}>
			<p>
				<strong>Plan:</strong> {plan.product.name}
			</p>
			<p>
				<strong>Amount:</strong> ${(plan.unit_amount / 100).toFixed(2)}{' '}
				{plan.currency.toUpperCase()}
			</p>
			<p>
				<strong>Billing Interval:</strong>{' '}
				{plan.recurring.interval_count} {plan.recurring.interval}(s)
			</p>
			<p>
				<strong>Description:</strong> {plan.product.description}
			</p>
			{plan.nickname && (
				<p>
					<strong>Note:</strong> {plan.nickname}
				</p>
			)}
			<Button
				type="primary"
				onClick={() => stripeCheckout(userEmail, plan.id)}
			>
				Subscribe
			</Button>
		</Card>
	)

	const filterPlansByCategory = (category) => {
		return plans.filter((plan) => plan.product.metadata.type === category)
	}

	const getUniqueTypes = () => {
		const types = plans.map((plan) => plan.product.metadata.type)
		return [...new Set(types)]
	}

	const RenderTabsContent = ({ children }) => {
		return (
			<Row gutter={[16, 16]}>
				{children.map((child, index) => (
					<Col key={index} span={8}>
						{child}
					</Col>
				))}
			</Row>
		)
	}
	RenderTabsContent.propTypes = {
		children: PropTypes.array
	}

	const renderTabs = (type) => {
		const items = [
			{
				key: 'monthly',
				label: 'Monthly',
				children: (
					<RenderTabsContent>
						{filterPlansByCategory(type)
							.filter(
								(plan) => plan.recurring.interval === 'month'
							)
							.map(renderPlanDetails)}
					</RenderTabsContent>
				)
			},
			{
				key: 'yearly',
				label: 'Yearly',
				children: (
					<RenderTabsContent>
						{filterPlansByCategory(type)
							.filter(
								(plan) => plan.recurring.interval === 'year'
							)
							.map(renderPlanDetails)}
					</RenderTabsContent>
				)
			}
		]
		return <Tabs items={items} />
	}

	return (
		<div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
			<Space align="center" style={{ marginTop: 25, marginBottom: 25 }}>
				<ReloadOutlined
					onClick={() => {
						runInAction(() => {
							subscriptions.setPlans([])
						})
						window.location.reload()
					}}
				/>
				<Title level={3} style={{ margin: 0 }}>
					Subscription Plans
				</Title>
			</Space>
			<Tabs
				defaultActiveKey="0"
				items={getUniqueTypes().map((type, index) => ({
					key: `${index}`,
					label: type,
					children: renderTabs(type)
				}))}
			/>
			<ActiveSubscriptions />
		</div>
	)
}

export default observer(SubscriptionOptions)
