import { remove, runInAction } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import root from '../../'

/**
 * @function startLoading
 * @description Starts a loading task with the given task information and adds it to the loadingTasks in the work progress store.
 * @param {string} taskInfo - The information about the task being loaded.
 * @returns {string} The unique ID of the loading task.
 */
export const startLoading = (taskInfo) => {
	const id = uuidv4()

	runInAction(() => {
		root.workProgress.loadingTasks[id] = {
			id,
			ti: taskInfo
		}
	})

	return id
}

/**
 * @function doneLoading
 * @description Removes a loading task from the loadingTasks in the work progress store.
 * @param {string} id - The unique ID of the loading task to be removed.
 */
export const doneLoading = (id) => {
	runInAction(() => {
		remove(root.workProgress.loadingTasks, id)
	})
}

/**
 * @function startPushing
 * @description Starts a pushing task with the given task information and adds it to the pushingTasks in the work progress store.
 * @param {string} taskInfo - The information about the task being pushed.
 * @returns {string} The unique ID of the pushing task.
 */
export const startPushing = (taskInfo) => {
	const id = uuidv4()

	runInAction(() => {
		root.workProgress.pushingTasks[id] = {
			id,
			ti: taskInfo
		}
	})

	return id
}

/**
 * @function donePushing
 * @description Removes a pushing task from the pushingTasks in the work progress store.
 * @param {string} id - The unique ID of the pushing task to be removed.
 */
export const donePushing = (id) => {
	runInAction(() => {
		remove(root.workProgress.pushingTasks, id)
	})
}

/**
 * @function startError
 * @description Starts an error task with the given error information and adds it to the errors in the work progress store.
 * @param {string} error - The information about the error.
 */

export const startError = (error) => {
	// If the error already exists, don't add it again
	if (
		Object.values(root.workProgress.errors).some((itm) => itm.ti === error)
	) {
		return
	}

	const id = uuidv4()

	runInAction(() => {
		root.workProgress.errors[id] = {
			id,
			ti: error,
			type: 'error'
		}
	})

	return id
}

/**
 * @function doneError
 * @description Removes an error task from the errors in the work progress store.
 * @param {string} id - The unique ID of the error task to be removed.
 */

export const doneError = (id) => {
	runInAction(() => {
		remove(root.workProgress.errors, id)
	})
}

export const clearErrors = () => {
	runInAction(() => {
		root.workProgress.errors = {}
	})
}
