import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/MobXProvider'
import OptionsSelector from './OptionsSelector'

// Category options: 1 Marketing, 2 Programming, 3 Web Design, 4 Graphic Design, 5 Content Writing
const AllowedCategories = observer(({ ...props }) => {
	const { userAllowedCategories } = useStores()
	const { optionStart, ...rest } = props
	const options = Object.keys(userAllowedCategories).map((key) => {
		return {
			key: `by-category-${key}`,
			label: userAllowedCategories[key].name,
			value: key
		}
	})

	// Add the option to start with
	if (optionStart) {
		options.unshift(optionStart)
	}

	return (
		<OptionsSelector
			placeholder="Select category"
			options={options}
			{...rest}
		/>
	)
})

export default AllowedCategories
