import React from 'react'
import { Typography } from 'antd'

const { Paragraph } = Typography

const HomePageTeam = () => {
	return (
		<>
			<Paragraph>HomePage for team</Paragraph>
		</>
	)
}

export default HomePageTeam
