import React from 'react'
import { Button, Typography, Row, Col } from 'antd'

const { Title, Paragraph } = Typography
import './css/CoverSection.css' // Create a CSS file to handle custom styles

const HomePage = () => {
	return (
		<>
			<div className="cover-container">
				<video autoPlay loop muted className="video-background">
					<source
						src="https://www.w3schools.com/howto/rain.mp4"
						type="video/mp4"
					/>
					Your browser does not support the video tag.
				</video>
				<div className="overlay">
					<Row justify="center" align="middle" className="content">
						<Col>
							<Title
								level={1}
								style={{ color: '#fff', textAlign: 'center' }}
							>
								Your Title Here 2
							</Title>
							<Paragraph
								style={{ color: '#fff', textAlign: 'center' }}
							>
								Your description goes here. It can be a short
								sentence or two describing your service or
								product.
							</Paragraph>
							<Row justify="center">
								<Button type="primary" size="large">
									Quick Link
								</Button>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}

export default HomePage
