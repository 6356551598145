/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CustomBadge } from './CustomBadge'
import { Button, Col, Row, Space } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const GeneralTourTitle = ({
	rowProps,
	titleProps,
	buttonProps,
	children,
	...props
}) => {
	const { align = 'center', ...restProps } = props
	return (
		<Row gutter={[16, 16]} align="middle" {...rowProps}>
			<Col>
				<Space align={align} {...restProps}>
					{titleProps?.text && <GeneralTitle {...titleProps} />}
					{buttonProps?.onClick && (
						<GeneralTourButton {...buttonProps} />
					)}
				</Space>
			</Col>
			<Col>{children}</Col>
		</Row>
	)
}
GeneralTourTitle.propTypes = {
	rowProps: PropTypes.object,
	titleProps: PropTypes.object,
	buttonProps: PropTypes.object,
	align: PropTypes.string,
	children: PropTypes.node
}

export const GeneralTourButton = ({ onClick, ...props }) => {
	return (
		<Button
			type={props?.type || 'link'}
			icon={props?.icon || <QuestionCircleOutlined />}
			onClick={onClick}
			{...props}
		>
			{props?.text}
		</Button>
	)
}
GeneralTourButton.propTypes = {
	onClick: PropTypes.func,
	type: PropTypes.string,
	icon: PropTypes.node,
	text: PropTypes.string
}

export const GeneralTitle = ({
	text,
	h = 'h1',
	className,
	children,
	...props
}) => {
	const H = h
	return (
		<H className={className} {...props}>
			{text}
			{children}
		</H>
	)
}
GeneralTitle.propTypes = {
	text: PropTypes.string,
	h: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	children: PropTypes.node
}

export const GeneralBcrumb = ({ itms }) => {
	return (
		<div className="bcrumb h-gap-medium h-flex">
			{itms.map((itm) => {
				return (
					<Link
						key={`${itm.to}`}
						className="bcrumb-item"
						to={itm.to}
						relative="path"
					>
						{itm.text}
					</Link>
				)
			})}
		</div>
	)
}
GeneralBcrumb.propTypes = {
	itms: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string,
			text: PropTypes.string
		})
	)
}

export const GeneralTabGroup = ({ itms }) => {
	return (
		<div className="tabgroup-style-3">
			{itms.map((itm) => {
				if (itm.disabled) return null
				if (itm.custom) {
					const CustomComponent = itm.custom
					return <CustomComponent key={itm.key} />
				}
				const linkElement = (
					<Link
						key={`${itm.to}`}
						to={itm.to}
						relative="path"
						className={`tab ${itm?.active ? 'active' : ''}`}
					>
						{itm.text}
					</Link>
				)

				return itm.count ? (
					<CustomBadge key={`${itm.to}`} count={itm.count}>
						{linkElement}
					</CustomBadge>
				) : (
					linkElement
				)
			})}
		</div>
	)
}
GeneralTabGroup.propTypes = {
	itms: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string,
			text: PropTypes.string,
			active: PropTypes.bool,
			count: PropTypes.number
		})
	)
}
