import { makeObservable, observable, action, toJS } from 'mobx'
import { get } from 'lodash'
import dayjs from 'dayjs'

class HistoryItem {
	value
	createdBy
	createdTs = dayjs().unix()

	constructor({ value, createdBy }) {
		this.value = value
		this.createdBy = createdBy

		makeObservable(this, {
			value: observable,
			createdBy: observable,
			createdTs: observable
		})
	}
}

export class History {
	categories = {}
	constructor(history = {}) {
		this.categories = history

		makeObservable(this, {
			categories: observable,
			addHistory: action,
			removeHistory: action,
			clearHistory: action
		})
	}

	vetCategory(category) {
		if (!this.categories[category]) {
			this.categories[category] = {}
		}
	}

	addHistory(category, id, item) {
		this.vetCategory(category)
		const _item = new HistoryItem(item)

		if (!this.categories[category][id]) {
			this.categories[category][id] = []
		}
		this.categories[category][id].push(_item)
	}

	removeHistory(category, id) {
		if (this.categories[category]) {
			delete this.categories[category][id]
		}
	}

	clearHistory(category) {
		if (this.categories[category]) {
			this.categories[category] = {}
		}
	}

	getHistory(category) {
		return get(this.categories, [category], {})
	}

	getHistoryItem(category, id, lastHistoryOnly = false) {
		if (!this.categories[category] || !this.categories[category][id])
			return []
		const history = this.categories[category][id]
			.slice()
			.sort((a, b) => b.createdTs - a.createdTs)
		return lastHistoryOnly ? history[0] : history
	}

	getFullHistoryItem(category, id, getAllValues = false) {
		const _id = id.toString().indexOf('_') > -1 ? id.split('_').pop() : id
		const history = this.getHistory(category)
		const fullHistory = []
		console.log('history', id, toJS(history))
		if (getAllValues) {
			Object.keys(history).forEach((key) => {
				fullHistory.push(...history[key])
			})
		} else {
			Object.keys(history).forEach((key) => {
				if (key.toString().indexOf(_id.toString()) > -1) {
					fullHistory.push(...history[key])
				}
			})
		}
		// sort by createdTs
		fullHistory.sort((a, b) => b.createdTs - a.createdTs)
		return fullHistory
	}
}
