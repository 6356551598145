import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/MobXProvider'

const TokenCountdown = observer(({ showLabels = true }) => {
	const { users } = useStores()

	useEffect(() => {
		// Ensure the countdown starts when the component mounts
		users.startTokenCountdown()
	}, [users])

	const countdown = users.tokenCountdown
	if (!countdown) return null

	const [hours, minutes, seconds] = countdown
		.split(':')
		.map((part) => part.replace(/[a-z]/g, ''))

	return (
		<div>
			{hours}
			{showLabels ? 'h' : ''}:{minutes}
			{showLabels ? 'm' : ''}:{seconds}
			{showLabels ? 's' : ''}
		</div>
	)
})

export default TokenCountdown
