import { addGETParams } from '../config'
import ax from './api'

/*
 * deleteLstItem - Delete a list item from the server
 * @param {string} targetLst - The target list to get from the server
 * @param {string} controlerId - Id of the user that has permission to delete specific list items
 * @param {object} params - Additional parameters to send to the server
 */
export const deleteLstItem = async (targetLst, params) => {
	const _url = addGETParams(`/delete/lst-item.php?lst=${targetLst}`, params)

	const { data } = await ax.get(_url)
	return data
}
