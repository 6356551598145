import { makeAutoObservable, runInAction } from 'mobx'
import { computedFn } from 'mobx-utils'

/**
 * @class WorkProgressStore
 * @description This class represents the store for managing work progress tasks. It keeps track of pushing and loading tasks and provides methods to add and remove tasks.
 */
class WorkProgressStore {
	/**
	 * @property {Object} pushingTasks - An object containing tasks that are currently being pushed, indexed by psuh ID.
	 * @property {Object} loadingTasks - An object containing tasks that are currently being loaded, indexed by load ID.
	 * @property {Object} errors - An object containing errors that occurred during the tasks, indexed by error ID.
	 */
	pushingTasks = {}
	loadingTasks = {}
	errors = {}

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of WorkProgressStore, makes it observable, and binds computed functions.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)
		this.isTaskLoading = computedFn(this.isTaskLoading.bind(this))
	}

	/**
	 * @computed
	 * @description Checks if there are any pushing tasks.
	 * @returns {boolean} True if there are pushing tasks, false otherwise.
	 */
	get hasPushingTasks() {
		return Object.keys(this.pushingTasks).length > 0
	}

	/**
	 * @computed
	 * @description Checks if there are any loading tasks.
	 * @returns {boolean} True if there are loading tasks, false otherwise.
	 */
	get hasLoadingTasks() {
		return Object.keys(this.loadingTasks).length > 0
	}

	/**
	 * @computedFn
	 * @description Checks if a specific task is currently loading.
	 * @param {string} taskInfo - The information of the task to check.
	 * @returns {boolean} True if the task is loading, false otherwise.
	 */
	isTaskLoading(taskInfo) {
		return Object.values(this.loadingTasks).some(
			(itm) => itm.ti === taskInfo
		)
	}

	/**
	 * @action
	 * @description Adds a task to the pushing tasks.
	 * @param {string} taskId - The ID of the task to add.
	 * @param {Object} taskInfo - The information of the task to add.
	 */
	addPushingTask(taskId, taskInfo) {
		runInAction(() => {
			this.pushingTasks[taskId] = taskInfo
		})
	}

	/**
	 * @action
	 * @description Removes a task from the pushing tasks.
	 * @param {string} taskId - The ID of the task to remove.
	 */
	removePushingTask(taskId) {
		runInAction(() => {
			delete this.pushingTasks[taskId]
		})
	}

	/**
	 * @action
	 * @description Adds a task to the loading tasks.
	 * @param {string} taskId - The ID of the task to add.
	 * @param {Object} taskInfo - The information of the task to add.
	 */
	addLoadingTask(taskId, taskInfo) {
		runInAction(() => {
			this.loadingTasks[taskId] = taskInfo
		})
	}

	/**
	 * @action
	 * @description Removes a task from the loading tasks.
	 * @param {string} taskId - The ID of the task to remove.
	 */
	removeLoadingTask(taskId) {
		runInAction(() => {
			delete this.loadingTasks[taskId]
		})
	}
}

export default WorkProgressStore
