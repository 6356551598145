import React, { useState } from 'react'
import { Button, List, Modal, Form, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import { Subtasks } from '../../modules/cls/subtasks'
import ProgressInput from './ProgressInput'
const { TextArea } = Input

const EditSubtask = observer(({ subtask, onSave, onCancel }) => {
	const [isModalVisible, setIsModalVisible] = useState(true)
	const editingSubtask = subtask

	return (
		<Modal
			title={editingSubtask ? 'Edit Subtask' : 'Add Subtask'}
			open={isModalVisible}
			onCancel={() => {
				setIsModalVisible(false)
				onCancel() // Ensure modal close state is updated in parent
			}}
			footer={null}
		>
			<Form
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={editingSubtask || { status: 'active' }}
				onFinish={onSave}
			>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: 'Please enter name' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{
							required: false,
							message: 'Please enter description'
						}
					]}
				>
					<TextArea rows={4} showCount />
				</Form.Item>
				<Form.Item
					label="Status"
					name="status"
					rules={[
						{ required: true, message: 'Please select status' }
					]}
				>
					<ProgressInput />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						{editingSubtask ? 'Update' : 'Add'}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
})

const SubtasksInput = observer(({ createdBy, value, onChange, ...props }) => {
	const { disabled } = props

	const subtasks = new Subtasks(value || [])
	// console.log('subtasks:', toJS(value))
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [editingSubtask, setEditingSubtask] = useState(null)

	const showModal = () => {
		setEditingSubtask(null)
		setIsModalVisible(true)
	}

	const save = () => {
		onChange(subtasks.prepareForSave())
	}

	const onSaveSubtask = (values) => {
		if (editingSubtask) {
			editingSubtask.name = values.name
			editingSubtask.description = values.description
			editingSubtask.status = values.status

			subtasks.updateSubtask(editingSubtask)
		} else {
			subtasks.addSubtask(
				values.name,
				values.description,
				values.status,
				createdBy
			)
		}
		save()
		setIsModalVisible(false)
	}

	const handleEdit = (subId) => {
		const subtask = subtasks.getSubtaskById(subId)
		setEditingSubtask(subtask)
		setIsModalVisible(true)
	}

	const handleDelete = (subtaskId) => {
		subtasks.deleteSubtask(subtaskId)
		save()
	}

	return (
		<>
			<List
				header={
					!disabled && (
						<Button type="dashed" onClick={showModal}>
							Add Subtask
						</Button>
					)
				}
				bordered
				dataSource={subtasks.list}
				renderItem={(subtask) => (
					<List.Item
						actions={
							!disabled
								? [
										<Button
											key={subtask.id + 'edit'}
											type="link"
											onClick={() =>
												handleEdit(subtask.id)
											}
										>
											Edit
										</Button>,
										<Button
											key={subtask.id + 'delete'}
											type="link"
											onClick={() =>
												handleDelete(subtask.id)
											}
										>
											Delete
										</Button>
								  ]
								: []
						}
					>
						{`${subtask.name} - ${subtask.status}`}
						{props?.displaySubtaskDescription &&
							subtask?.description && (
								<div
									style={{
										fontSize: 12,
										color: '#7d7d7d',
										margin: '0 0 0 10px',
										padding: 0
									}}
								>
									{subtask.description}
								</div>
							)}
					</List.Item>
				)}
			/>
			{isModalVisible && (
				<EditSubtask
					subtask={editingSubtask}
					onSave={onSaveSubtask}
					onCancel={() => setIsModalVisible(false)}
				/>
			)}
		</>
	)
})

export default SubtasksInput
