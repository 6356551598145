import React from 'react'
import {
	SyncOutlined,
	ThunderboltOutlined,
	EditOutlined,
	CloseCircleOutlined,
	EyeOutlined,
	CheckCircleFilled
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import CustomTag from './CustomTag'

const StatusTag = ({ tag, fallback, isShort, ...props }) => {
	const tags = {
		canceled: (
			<CustomTag tooltip="Progress: canceled" color="error" {...props}>
				{!isShort ? 'canceled' : <CloseCircleOutlined />}
			</CustomTag>
		),
		draft: (
			<CustomTag tooltip="Progress: draft" color="warning" {...props}>
				{!isShort ? 'draft' : <EditOutlined />}
			</CustomTag>
		),
		in_review: (
			<CustomTag tooltip="Progress: in review" color="warning" {...props}>
				{!isShort ? 'in review' : <EyeOutlined />}
			</CustomTag>
		),
		active: (
			<CustomTag tooltip="Progress: active" color="default" {...props}>
				{!isShort && 'active'}
				<ThunderboltOutlined />
			</CustomTag>
		),
		pending: (
			<CustomTag
				tooltip="Progress: pending"
				icon={!isShort && <SyncOutlined spin />}
				color="processing"
				{...props}
			>
				{!isShort ? 'processing' : <SyncOutlined spin />}
			</CustomTag>
		),
		completed: (
			<CustomTag tooltip="Progress: completed" color="success" {...props}>
				{!isShort ? 'completed' : <CheckCircleFilled />}
			</CustomTag>
		),
		default: (
			<CustomTag tooltip="Progress: default" color="default" {...props}>
				-
			</CustomTag>
		)
	}

	if (!tag || !tags[tag]) return fallback || tags.default

	return tags[tag]
}

StatusTag.propTypes = {
	tag: PropTypes.string,
	fallback: PropTypes.node,
	style: PropTypes.object,
	isShort: PropTypes.bool
}

export default StatusTag
