import React from 'react'
import PropTypes from 'prop-types'
import { Routes, Route } from 'react-router-dom'
import { Layout } from 'antd'

import '../../App.css'
import { ErrorBoundary } from '../../components/Shared/ErrorBoundary'
import { observer } from 'mobx-react-lite'
import { Notifications } from './Notifications'
import rootStore from '../../stores'

import { contentStyle } from '../../design/app-style-config'

// Pages
import Logout from '../../pages/Logout'
import { flattenRoutesList } from '../../pages/routes'
import UsersMain from '../../pages/Users/UsersMain'
import RequestModal from '../../pages/Requests/RequestModal'
import { useStores } from '../../stores/MobXProvider'

const { Content } = Layout

const MainContent = observer(() => {
	const { users, appType } = useStores()
	const { appIsClient, appIsTeam } = appType

	// add is logged in check for RequestModal
	return (
		<Content style={contentStyle}>
			<Routes>
				<Route
					key={'/users'}
					path={'/users/*'}
					element={
						<ErrorBoundary>
							<UsersMain />
						</ErrorBoundary>
					}
				/>
				{flattenRoutesList.map((route) => {
					// Perform the checks
					if (route.loggedInDisable && users.isLoggedIn) return null
					if (route.loggedInActive && !users.isLoggedIn) return null
					if (
						route.appType &&
						route.appType === 'client' &&
						!appIsClient
					)
						return null
					if (route.appType && route.appType === 'team' && !appIsTeam)
						return null
					if (route.published === false) return null

					// Render the route if it passes all checks
					return (
						<Route
							key={route.key}
							path={route.path + (route?.pathWithChild || '')}
							element={
								<ErrorBoundary>
									<route.component />
								</ErrorBoundary>
							}
						/>
					)
				})}

				<Route
					path="/logout"
					element={<Logout rootStore={rootStore} />}
				/>
			</Routes>
			<RequestModal />
			<Notifications />
		</Content>
	)
})

MainContent.propTypes = {
	collapsed: PropTypes.bool,
	setCollapsed: PropTypes.func
}

export default MainContent
