import React from 'react'
import { Tag, Tooltip } from 'antd'
import PropTypes from 'prop-types'

const CustomTag = ({ tooltip, ...props }) => {
	return (
		<Tag {...props}>
			{tooltip ? (
				<Tooltip title={tooltip}>{props.children}</Tooltip>
			) : (
				props.children
			)}
		</Tag>
	)
}

CustomTag.propTypes = {
	tooltip: PropTypes.string,
	children: PropTypes.node
}
export default CustomTag
