import React from 'react'
import { Segmented } from 'antd'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/MobXProvider'

/**
 * ProgressInput component renders a range time picker.
 * Allows users to select the progress of an item.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The current progress value of the item
 * @param {function} props.onChange - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered Segmented component.
 */
const ProgressInput = ({ value, onChange, ...props }) => {
	const { phaseTwoLimited, ...rest } = props
	const { users } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	//TODO: transform isAdmin check into has team permission check when implementing team ecosystem

	const stepCanceled = {
		label: 'Canceled',
		value: 'canceled'
	}

	const phase_one = [
		{
			label: 'Draft',
			value: 'draft',
			disabled: value === 'in_review' && !isAdmin
		},
		{
			label: 'In review',
			value: 'in_review'
		}
	]

	// if in_review, and isAdmin, add a new option: "aprove" with value active
	if (value === 'in_review' && isAdmin) {
		phase_one.push({
			label: 'Aproved',
			value: 'active'
		})
	}

	const phase_two = [
		stepCanceled,
		{
			label: 'Active',
			value: 'active'
			// disabled: !isAdmin
		}
	]
	if (!phaseTwoLimited) {
		phase_two.push({
			label: 'Pending',
			value: 'pending'
			// disabled: !isAdmin
		})
		phase_two.push({
			label: 'Completed',
			value: 'completed'
			// disabled: !isAdmin
		})
	}

	const phase_three = [...phase_one, stepCanceled]

	const in_phase_one = value === 'draft' || value === 'in_review'
	const in_phase_three = value === 'canceled'

	// Curent options
	const options = in_phase_one
		? phase_one
		: !in_phase_three
		? phase_two
		: phase_three

	return (
		<Segmented
			value={value}
			onChange={onChange}
			style={{
				border: '1px solid #d9d9d9'
			}}
			options={options}
			{...rest}
		/>
	)
}

/**
 * Prop types for the ProgressInput component.
 */
ProgressInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	phaseTwoLimited: PropTypes.bool
}

export default observer(ProgressInput)
