/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, Outlet } from 'react-router-dom'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { dateTimeNow, DO_LOAD, IS_NEW } from '../../config'
import { isModifiedItm } from '../../stores/general-actions'
import { useStores } from '../../stores/MobXProvider'

const PageLoader = ({ forceLoad, requireItm, loadItm, targetLst }) => {
	const [loadingItm, setLoadingItm] = useState(true)
	const { itmId } = useParams()
	const { hasIntervalLoad } = useStores()
	const _itm = requireItm(itmId)
	const intervalIdRef = useRef(null) // Use ref for intervalId
	const [errorKey, setErrorKey] = useState(null)
	const withIntervalLoad = hasIntervalLoad(itmId)

	useEffect(() => {
		// Function to cancel interval
		const cancelCheckAndLoad = () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
			}
		}

		// Function to check if the item is modified and load it if necessary
		const checkAndLoadItem = () => {
			if (_itm?.modified && targetLst && errorKey !== 'not_found') {
				isModifiedItm(_itm.modified, targetLst, { id: itmId })
					.then((res) => {
						if (res) {
							console.log(
								dateTimeNow(),
								'item was modified, loading...'
							)
							loadItm(itmId).catch((err) => {
								// This will catch errors thrown by wrapWithLoading
								console.error('Error loading item:', err)

								// Handle the specific error by setting the errorKey
								if (
									err &&
									err.status === 'error' &&
									err?.reason !== ''
								) {
									setErrorKey(err.reason)
								} else {
									setErrorKey(null)
								}
							})
						}
					})
					.catch(console.error)
			} else if (errorKey === 'not_found') {
				cancelCheckAndLoad()
			}
		}

		// Load item initially
		if (_itm.loaded === DO_LOAD || (_itm.loaded !== IS_NEW && forceLoad)) {
			loadItm(itmId)
				.catch((err) => {
					// This will catch errors thrown by wrapWithLoading
					console.error('Error loading item:', err)

					// Handle the specific error by setting the errorKey
					if (err && err.status === 'error' && err?.reason !== '') {
						setErrorKey(err.reason)
					} else {
						setErrorKey(null)
					}
				})
				.finally(() => {
					setLoadingItm(false)
				})
		} else {
			setLoadingItm(false)
		}

		// Polling interval to check for modifications every 5 seconds
		if (withIntervalLoad && !!!_itm.to_delete) {
			intervalIdRef.current = setInterval(checkAndLoadItem, 5000)
		} else {
			cancelCheckAndLoad()
		}
		// Clean up interval on component unmount
		return () => cancelCheckAndLoad()
	}, [itmId, _itm.modified, targetLst, errorKey, withIntervalLoad]) // Ensure dependencies are correctly set

	if (loadingItm) {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>Loading...</p>
				<Spin />
			</div>
		)
	}

	if (errorKey === 'not_found') {
		return <div style={{ textAlign: 'center' }}>Not found</div>
	}

	return (
		<>
			<Outlet />
		</>
	)
}

PageLoader.propTypes = {
	forceLoad: PropTypes.bool,
	requireItm: PropTypes.func.isRequired,
	loadItm: PropTypes.func.isRequired,
	targetLst: PropTypes.string.isRequired
}

export default observer(PageLoader)
