import React from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { Row, Col, Input, Checkbox, Button, ConfigProvider } from 'antd'
import OptionsSelector from './OptionsSelector'
import AllowedCategories from './AllowedCategories'

const { Search } = Input

export const defaultFilterOptions = [
	{
		key: 'by-progress',
		value: '',
		label: 'By Status'
	},
	{
		key: 'by-progress-canceled',
		value: 'canceled',
		label: 'Canceled'
	},
	{
		key: 'by-progress-draft',
		value: 'draft',
		label: 'Draft'
	},
	{
		key: 'by-progress-in_review',
		value: 'in_review',
		label: 'In review'
	},
	{
		key: 'by-progress-active',
		value: 'active',
		label: 'Active'
	},
	{
		key: 'by-progress-pending',
		value: 'pending',
		label: 'Pending'
	},
	{
		key: 'by-progress-completed',
		value: 'completed',
		label: 'Completed'
	}
]

/*
 * Filter
 * @description Filter component for filtering list items.
 * @param {object} uiStore - The MobX store for the UI.
 * @param {function} addNew - The function to add a new item.
 * @param {array} progressOptions - The progress options.
 * @param {array} hide - ['search', 'progress', 'category', 'deleted', 'addNew']
 */
const Filter = ({ uiStore, addNew, progressOptions = [], hide = [] }) => {
	// progress options: enum('canceled','draft','in_review','active','pending','completed')
	const _progressOptions =
		progressOptions.length > 0 ? progressOptions : defaultFilterOptions

	const filterValue = uiStore.filter

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#1b1d1f'
				},
				components: {
					Input: {
						colorTextPlaceholder: '#1b1d1f'
					},
					Select: {
						optionSelectedColor: '#fff'
					}
				}
			}}
		>
			<Row
				id="target-filter"
				align="middle"
				gutter={[10, 10]}
				style={{
					width: '100%',
					borderRadius: 4
				}}
			>
				{!hide.includes('search') && (
					<Col id="target-filter-search">
						<Search
							defaultValue={uiStore.search || ''}
							placeholder="Search..."
							onSearch={(val) => uiStore.setSearch(val)}
							enterButton
							allowClear
						/>
					</Col>
				)}
				{!hide.includes('progress') && (
					<Col id="target-filter-progress">
						<OptionsSelector
							value={filterValue?.progress || ''}
							onChange={(val) =>
								uiStore.setFilter('progress', val)
							}
							options={_progressOptions}
						/>
					</Col>
				)}
				{!hide.includes('category') && (
					<Col flex="auto" id="target-filter-category">
						<AllowedCategories
							value={filterValue?.category || ''}
							onChange={(val) =>
								uiStore.setFilter('category', val)
							}
							optionStart={{
								key: 'by-category',
								value: '',
								label: 'By category'
							}}
						/>
					</Col>
				)}
				{!hide.includes('deleted') && (
					<Col id="target-filter-deleted">
						<Checkbox
							checked={uiStore?.del || false}
							onChange={(e) => uiStore.setDel(e.target.checked)}
						>
							Deleted
						</Checkbox>
					</Col>
				)}
				{addNew && !hide.includes('addNew') && (
					<Col flex="auto" style={{ textAlign: 'right' }}>
						<Button id="target-filter-addnew" onClick={addNew}>
							Add new
						</Button>
					</Col>
				)}
			</Row>
		</ConfigProvider>
	)
}

Filter.propTypes = {
	uiStore: PropTypes.object.isRequired,
	addNew: PropTypes.func,
	progressOptions: PropTypes.array,
	hide: PropTypes.array
}

export default observer(Filter)
