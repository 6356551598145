import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { useStores } from '../../stores/MobXProvider'
import { DO_LOAD } from '../../config'
import { actLoadUser } from '../../stores/users/actions'
import get from 'lodash/get'

const UserString = observer(({ userId, path, include_full = false }) => {
	const { users } = useStores()
	const user = users.requireUserById(userId)

	useEffect(() => {
		// Fetch only if user is empty
		if (user.loaded === DO_LOAD) {
			actLoadUser(users, userId, include_full)
				.catch(console.error)
				.finally(() => {
					users.saveState()
				})
		}
	}, [userId]) // Depend only on userId

	const userValue = get(user, path, '---')

	return userValue
})

UserString.propTypes = {
	userId: PropTypes.string.isRequired,
	path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
	include_full: PropTypes.bool
}

export default UserString
