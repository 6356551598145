import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../stores/MobXProvider'

export const SelectUsers = observer(
	({ type, value = [], onChange, ...props }) => {
		const { users } = useStores()
		const { loggedInUser } = users
		const isAdmin = loggedInUser.isAdmin
		const isSuperAdmin = loggedInUser.isSuperAdmin
		console.log(
			'isSuperAdmin:',
			isSuperAdmin,
			'create getter of full users list'
		)
		// Default options to show
		// TODO: superAdmin added, so we need to limit admin to allowed users, and only superAdmin can see all users
		const ids = isAdmin ? loggedInUser.userIds : loggedInUser[type]
		const options = ids.map((id) => {
			// TODO: I need to load the user data due to refresh problems
			const user = users.requireUserById(id)

			return {
				value: id,
				label: user.email
			}
		})

		return (
			<Select
				value={value}
				onChange={onChange}
				filterOption={false}
				options={options}
				{...props}
			/>
		)
	}
)

SelectUsers.propTypes = {
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onChange: PropTypes.func
}
