import { addGETParams } from '../config'
import ax from './api'

/*
 * User get and set (update or insert)
 * @param {string} userId - The id of the user
 * $returns {object} The response from the server
 */
export const getUserByUid = async (userId, include_full = 'false') => {
	return await ax.get(
		`/user/get.php?user_id=${userId}&include_full=${include_full}`
	)
}

/*
 * Set a user on the server
 * @param {string} userId - The id of the user
 * @param {object} full - The full object of the user
 * @returns {object} The response from the server
 */
export const setUser = async (userId, full) => {
	// Convert full object to JSON string
	const fullStr = JSON.stringify(full)
	const _url = addGETParams(`/user/set.php`, {
		user_id: userId,
		full: fullStr
	})

	return await ax.post(_url)
}

/*
 * User login and registration
 * @param {object} values - The values to send to the server
 * @returns {object} The response from the server
 * @example
 * const values = {
  	username: 'myusername',
  	password: 'mypassword'
  }
 *
 */

export const login = async (values) => {
	const { data } = await ax.post('/login.php', values)
	return data
}

/*
 * Register a new user
 * @param {object} values - The values to send to the server
 * @returns {object} The response from the server
 * @example
 * const values = {
  	username: 'myusername',
  	password: 'mypassword'
  }
 *
 */
export const register = async (values) => {
	const { data } = await ax.post('/register.php', values)
	return data
}
