import React from 'react'
import { TimePicker } from 'antd'
import PropTypes from 'prop-types'

/**
 * TimesRange component renders a range time picker.
 * Allows users to select a start and end time with a 10-minute step interval.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.value - The current value of the time range picker.
 * @param {function} props.onChange - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered TimePicker.RangePicker component.
 */
const TimesRange = ({ value, onChange }) => {
	return (
		<TimePicker.RangePicker
			allowEmpty
			value={value}
			onChange={onChange}
			minuteStep={10}
			use12Hours
			format="HH:mm"
			showNow
			needConfirm={true}
		/>
	)
}

/**
 * Prop types for the TimesRange component.
 */
TimesRange.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
}

export default TimesRange
