// Clear console errors in development
const ignoredErrors = ['findDOMNode']
if (process.env.NODE_ENV === 'development') {
	const originalError = console.error.bind(console.error)
	console.error = (msg, ...args) => {
		if (
			typeof msg === 'string' &&
			!ignoredErrors.some((errorText) => msg.includes(errorText))
		) {
			originalError(msg, ...args)
		} else if (typeof msg !== 'string') {
			originalError(msg, ...args)
		}
	}
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
