import React, { useEffect } from 'react'
import { runInAction } from 'mobx'
import { Typography, Table, Tooltip, Space, Tag } from 'antd'
import {
	InfoCircleOutlined,
	ExclamationCircleOutlined,
	ReloadOutlined
} from '@ant-design/icons'

import { useStores } from '../../stores/MobXProvider'
import {
	stripeGetSubscriptionStatus,
	stripeLoadPlans
} from '../../stores/subscriptions/actions'
import { observer } from 'mobx-react-lite'
const { Title } = Typography

const ActiveSubscriptions = () => {
	const { users, subscriptions } = useStores()

	const plans = subscriptions.plans

	const { loggedInUserId } = users
	const user = users.requireUserById(loggedInUserId)
	const userSubscriptions = user.subscriptions

	useEffect(() => {
		// Fetch plans if not already loaded
		plans.length === 0 && stripeLoadPlans()
		userSubscriptions.length === 0 &&
			stripeGetSubscriptionStatus(loggedInUserId)
	}, [])

	const getPlanDetails = (priceId) => {
		const plan = plans.find((plan) => plan.id === priceId)
		return plan
			? { name: plan.product.name, description: plan.product.description }
			: { name: priceId, description: 'No description available' }
	}

	const columns = [
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			render: (subscriptionId, record) => {
				const priceId = record.items.data[0].price.id
				const { description } = getPlanDetails(priceId)
				return (
					<Tooltip
						title={
							<div>
								<p>
									<strong>ID:</strong> {subscriptionId}
								</p>
								<p>
									<strong>Description:</strong> {description}
								</p>
							</div>
						}
					>
						<InfoCircleOutlined />
					</Tooltip>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag color={status === 'active' ? 'green' : 'default'}>
					{status}
				</Tag>
			)
		},
		{
			title: 'Plan',
			dataIndex: ['items', 'data', 0, 'price', 'id'],
			key: 'plan',
			render: (priceId) => getPlanDetails(priceId).name
		},
		{
			title: 'Amount',
			dataIndex: ['items', 'data', 0, 'price', 'unit_amount'],
			key: 'amount',
			render: (amount, record) =>
				`$${(amount / 100).toFixed(
					2
				)} ${record.items.data[0].price.currency.toUpperCase()}`
		},
		{
			title: 'Billing Interval',
			dataIndex: [
				'items',
				'data',
				0,
				'price',
				'recurring',
				'interval_count'
			],
			key: 'billingInterval',
			render: (interval_count, record) =>
				`${interval_count} ${record.items.data[0].price.recurring.interval}(s)`
		},
		{
			title: 'Current Period Start',
			dataIndex: 'current_period_start',
			key: 'currentPeriodStart',
			render: (date) => new Date(date * 1000).toLocaleDateString()
		},
		{
			title: 'Current Period End',
			dataIndex: 'current_period_end',
			key: 'currentPeriodEnd',
			render: (date) => new Date(date * 1000).toLocaleDateString()
		}
	]

	return (
		<div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
			<Space align="center" style={{ marginTop: 50, marginBottom: 25 }}>
				<ReloadOutlined
					onClick={() => {
						runInAction(() => {
							user.setSubscriptions([])
							users.saveState()
						})
						window.location.reload()
					}}
				/>
				<Title level={3} style={{ margin: 0 }}>
					Your Subscriptions
				</Title>
			</Space>

			{userSubscriptions?.length ? (
				<Table
					columns={columns}
					dataSource={userSubscriptions}
					rowKey="id"
					pagination={false}
				/>
			) : (
				<Title level={4}>
					<ExclamationCircleOutlined /> No active subscriptions
				</Title>
			)}
		</div>
	)
}

export default observer(ActiveSubscriptions)
