import axios from 'axios'
import { API_BASE_URL } from '../config'

export const getAuthDetails = () => {
	const local = JSON.parse(localStorage.getItem('usersStore'))
	const appType = JSON.parse(localStorage.getItem('appType'))
	return {
		token: local?.apiToken || '',
		loggedInUserId: local?.loggedInUserId || '',
		appType: appType || ''
	}
}

const axiosInstance = axios.create({
	baseURL: API_BASE_URL,
	withCredentials: true // Ensure credentials are included in requests
})

axiosInstance.interceptors.request.use(
	(config) => {
		const { token, loggedInUserId, appType } = getAuthDetails()
		if (token) {
			config.headers['Authorization'] = token
		}
		if (loggedInUserId) {
			const params = new URLSearchParams(config.params || {})
			params.append('controller_id', loggedInUserId)
			params.append('app_type', appType)
			config.params = params
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

export default axiosInstance
