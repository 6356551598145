import React from 'react'
import { Row, Col, Card } from 'antd'

const { Meta } = Card

const blogData = [
	{
		id: 1,
		title: 'Understanding React Hooks',
		image: 'https://via.placeholder.com/300x200', // Replace with your image URL
		link: '/blog/react-hooks'
	},
	{
		id: 2,
		title: 'Getting Started with Ant Design',
		image: 'https://via.placeholder.com/300x200',
		link: '/blog/ant-design'
	},
	{
		id: 3,
		title: 'Building Responsive Web Apps',
		image: 'https://via.placeholder.com/300x200',
		link: '/blog/responsive-web-apps'
	},
	{
		id: 4,
		title: 'State Management in React',
		image: 'https://via.placeholder.com/300x200',
		link: '/blog/state-management'
	},
	{
		id: 5,
		title: 'Exploring Next.js Features',
		image: 'https://via.placeholder.com/300x200',
		link: '/blog/nextjs-features'
	},
	{
		id: 6,
		title: 'Using TypeScript with React',
		image: 'https://via.placeholder.com/300x200',
		link: '/blog/typescript-react'
	}
]

const BlogList = () => {
	return (
		<>
			<h1>Blog List</h1>
			<p>Discover the latest articles from our blog.</p>
			<Row gutter={[16, 16]}>
				{blogData.map((blog) => (
					<Col xs={24} sm={12} lg={8} key={blog.id}>
						<Card
							hoverable
							cover={<img alt={blog.title} src={blog.image} />}
						>
							<Meta
								title={<a href={blog.link}>{blog.title}</a>}
							/>
						</Card>
					</Col>
				))}
			</Row>
		</>
	)
}

export default BlogList
