import React, { useEffect, useState } from 'react'
import { runInAction } from 'mobx'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { observer } from 'mobx-react-lite'
import { Button, Popconfirm, Select, Space, Tag, Typography } from 'antd'
import { UserOutlined, DeploymentUnitOutlined } from '@ant-design/icons'

import { useStores } from '../../stores/MobXProvider'
import {
	actLoadRequests,
	actUndoOrDeleteRequest
} from '../../stores/ui-lst-requests/actions'

import {
	StatusTag,
	Filter,
	UserString,
	LstTable,
	CustomTable,
	FormatedTimestamp,
	BetweenDates
} from '../../components/Shared'
import { recurrenceLabels } from '../../components/Form/recurrence/labels'
import {
	newRequest,
	requireRequestById,
	actLoadRequest,
	actSaveRequest
} from '../../stores/requests/actions'
import ProgressModal from '../../components/Form/ProgressModal'

const { Title } = Typography

const LstItem = observer(({ id }) => {
	const { uiLstRequests, users, categories } = useStores()
	const { loggedInUser } = users
	const isAdmin = loggedInUser.isAdmin
	const request = requireRequestById(id)

	// Delete is active only for the creator of the request if is in draft mode or if the user is an admin
	const deleteIsDisabled = !isAdmin
		? !(
				request.progress === 'not_started' &&
				(loggedInUser.user_id === request.createdBy ||
					loggedInUser.user_id === request.user_id)
		  )
		: false

	const [showConfirmUndoOrDelete, setConfirmUndoOrDelete] = useState(false)
	const confirmUndoOrDelete = () => {
		setConfirmUndoOrDelete(false)

		if (!!request.to_delete) {
			actUndoOrDeleteRequest(id, false).catch(console.error)
		} else {
			actUndoOrDeleteRequest(id, true).catch(console.error)
		}
	}

	const _actions = [
		{
			key: 'data',
			label: 'Edit',
			value: 'data'
		}
	]

	if (
		true
		// if user has permissions to change progress
	) {
		_actions.push({
			key: 'status',
			label: 'Set Progress',
			value: 'status'
		})
	}

	// Delete / Undo actions
	if (request.to_delete) {
		_actions.push({
			key: 'undo-del',
			label: 'Undo Delete',
			value: 'undo-delete',
			disabled: deleteIsDisabled
		})
	} else {
		_actions.push({
			key: 'del',
			label: 'Delete',
			value: 'delete',
			disabled: deleteIsDisabled
		})
	}

	const handleActions = (value) => {
		switch (value) {
			case 'data':
				runInAction(() => {
					uiLstRequests.setModal('request-modal', {
						visible: true,
						id
					})
				})
				break
			case 'undo-delete':
				setConfirmUndoOrDelete(true)
				break
			case 'delete':
				setConfirmUndoOrDelete(true)
				break
			case 'status':
				runInAction(() => {
					uiLstRequests.setModal('request-progress', {
						visible: true,
						itmId: id
					})
				})
				break
			default:
				break
		}
	}
	return (
		<tr>
			<td>{request.name}</td>
			<td>
				{loggedInUser.user_id === request.user_id ? (
					<UserOutlined />
				) : (
					<Tag color="default">
						<UserString userId={request.user_id} path="name" />
					</Tag>
				)}
			</td>
			<td>{categories[request?.category_id]?.name || '-'}</td>
			<td>
				{request.recurrenceType && (
					<CustomTable
						data={[
							[
								'Type',
								{
									item:
										recurrenceLabels[
											request.recurrenceType
										] || '*' + request.recurrenceType
								}
							],

							[
								'Next',
								{
									item: request.recurrenceNext ? (
										<FormatedTimestamp
											timestamp={request.recurrenceNext}
										/>
									) : request.progress !== 'completed' ? (
										'*requires update'
									) : (
										'completed'
									)
								}
							],
							request.recurrenceType !== 'deadline' && [
								'Start/End',
								{
									item: (
										<BetweenDates
											timestamps={
												request.recurrenceStartEnd
											}
										/>
									)
								}
							]
						]}
					/>
				)}
			</td>
			<td>
				<div style={{ maxWidth: 260 }}>
					<CustomTable
						data={[
							[
								'Created',
								{
									item: (
										<FormatedTimestamp
											timestamp={request.createdTs}
											formatedAs="DD.MM.YYYY hh:mm"
										/>
									)
								}
							],
							[
								'Progress',
								{
									item: (
										<StatusTag
											tag={request.progress}
											style={{ marginRight: 0 }}
										/>
									)
								}
							]
						]}
					/>
				</div>
			</td>
			<td>
				<Space>
					<Button
						color="default"
						icon={<DeploymentUnitOutlined />}
						onClick={() => {
							runInAction(() => {
								uiLstRequests.setModal('request-modal', {
									visible: true,
									itmId: id
								})
							})
						}}
					/>
					<Select
						value="Actions"
						options={_actions}
						onChange={handleActions}
						style={{ width: 120 }}
					/>
				</Space>

				<Popconfirm
					title={
						request.to_delete
							? 'Confirm undo delete'
							: 'Confirm delete'
					}
					open={showConfirmUndoOrDelete}
					onConfirm={confirmUndoOrDelete}
					onCancel={() => setConfirmUndoOrDelete(false)}
					okType="link"
					placement="topLeft"
				/>
			</td>
		</tr>
	)
})
LstItem.propTypes = {
	id: PropTypes.string.isRequired
}
const RequestsLst = observer(() => {
	const { uiLstRequests } = useStores()

	const bySearch = uiLstRequests.search
	const byDel = uiLstRequests.del
	const byProgress = uiLstRequests.filter.progress

	useEffect(() => {
		actLoadRequests()
	}, [bySearch, byDel, byProgress])

	return (
		<Space direction="vertical" style={{ width: '100%', padding: 20 }}>
			<Title
				level={1}
				style={{ fontSize: 25, marginTop: 0, marginBottom: 10 }}
			>
				Requests history
			</Title>
			<Filter
				uiStore={uiLstRequests}
				addNew={() => {
					const newId = uuidv4()
					newRequest(newId).then(() => {
						runInAction(() => {
							uiLstRequests.setModal('request-modal', {
								visible: true,
								itmId: newId
							})
						})
						console.log('new request created', newId)
					})
				}}
				hide={['category']}
				progressOptions={[
					{
						key: 'by-progress',
						value: '',
						label: 'By Status'
					},
					{
						key: 'by-progress-not-read',
						value: 'not_read',
						label: 'Not read'
					},
					{
						key: 'by-progress-completed',
						value: 'completed',
						label: 'Completed'
					}
				]}
			/>
			<ProgressModal
				modalKey="request-progress"
				uiStore={uiLstRequests}
				requireItemById={requireRequestById}
				loadItm={(id) => actLoadRequest(id)}
				saveItem={actSaveRequest}
			/>
			<LstTable
				LstItem={LstItem}
				store={uiLstRequests}
				className="table-pro"
			>
				<tr>
					<th>Request</th>
					<th>Product Owner</th>
					<th>Category</th>
					<th>Recurrence</th>
					<th>Info</th>
					<th>Actions</th>
				</tr>
			</LstTable>
		</Space>
	)
})
export default RequestsLst
