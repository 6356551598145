import React from 'react'
import { ConfigProvider } from 'antd'
import PropTypes from 'prop-types'

/**
 * ConfigProvider settings for the application.
 * Follow up on:
 * https://ant.design/docs/react/migrate-less-variables#button
 * node_modules/antd/es/theme/interface/components.d.ts
 */
const AppDesign = ({ children }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#4846f4'
				},
				components: {
					Segmented: {
						itemSelectedBg: '#4846f4',
						itemSelectedColor: '#fff',
						trackBg: 'unset'
					},
					Form: {
						itemMarginBottom: '25px'
					},
					Button: {
						defaultBg: 'transparent'
						// defaultGhostColor: 'red'
					},
					Input: {
						// colorTextPlaceholder: '#1b1d1f'
						colorBgContainer: 'unset'
					},
					Select: {
						selectorBg: 'unset'
						// optionSelectedBg: 'red'
					},
					Segmented: {
						// set selected to primary color
						itemSelectedBg: '#4846f4',
						itemSelectedColor: '#fff'
						// trackBg: 'unset',
					},
					Menu: {
						// Remove theme="dark" param from Menu component when ready to design menu
					}
				}
			}}
		>
			{children}
		</ConfigProvider>
	)
}

AppDesign.propTypes = {
	children: PropTypes.node.isRequired
}
export default AppDesign
