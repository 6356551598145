import { makeAutoObservable } from 'mobx'

/**
 * @class RequestsStore
 * @description This class represents the store for requests. It manages the state of requests and is part of the root store.
 */
class RequestsStore {
	/**
	 * @property {Object} requests - An object containing requests indexed by their IDs.
	 */
	requests = {}

	/**
	 * @constructor
	 * @param {Object} rootStore - The root store that holds the global state and other stores.
	 * @description Constructs an instance of RequestsStore and makes it observable.
	 */
	constructor(rootStore) {
		this.rootStore = rootStore
		makeAutoObservable(this)
	}
}

export default RequestsStore
