import React from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { login } from '../modules/api-user'
import { useStores } from '../stores/MobXProvider'
import { clearErrors, startError } from '../stores/work-progress/actions'

const { Text } = Typography

const LoginComponent = observer(() => {
	const { users } = useStores()
	const navigate = useNavigate()
	const [passwordVisible, setPasswordVisible] = React.useState(false)

	const onFinish = async (values) => {
		try {
			login(values)
				.then((response) => {
					if (response.status === 'success') {
						const userId = response.user.user_id
						users.setLoggedInUser(userId, response.apiToken)
						const user = users.requireUserById(userId)
						user.updateFromServer(response.user)
						users.saveState()
						clearErrors()

						if (users.redirectOnLogin) {
							const url = users.redirectOnLogin
							users.clearRedirectOnLogin()
							navigate(url)
						} else {
							navigate('/dashboard')
						}
					} else {
						startError(response.message)
					}
				})
				.catch((error) => {
					startError(error.message)
					console.error('Failed to fetch user info:', error)
				})
		} catch (error) {
			console.error('Login failed:', error)
			startError('Login failed, please try again.')
		}
	}

	return (
		<Form
			name="login_form"
			initialValues={{ remember: true }}
			onFinish={onFinish}
			layout="vertical"
		>
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{ required: true, message: 'Please input your email!' }
				]}
			>
				<Input placeholder="Email" />
			</Form.Item>

			<Form.Item
				name="password"
				label="Password"
				rules={[
					{ required: true, message: 'Please input your password!' }
				]}
			>
				<Input.Password
					placeholder="Password"
					visibilityToggle={{
						visible: passwordVisible,
						onVisibleChange: setPasswordVisible
					}}
				/>
			</Form.Item>

			<Form.Item>
				<Button
					type="primary"
					htmlType="submit"
					style={{ width: '100%' }}
				>
					Log In
				</Button>
			</Form.Item>

			<Form.Item>
				<Text style={{ display: 'block', textAlign: 'center' }}>
					Don't have an account?{' '}
					<Link to="/register">Register now</Link>
				</Text>
			</Form.Item>
		</Form>
	)
})

export default LoginComponent
