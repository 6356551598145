import React, { useEffect, useState } from 'react'
import { Input, Button, Select, Row, Col, Space } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

const { Option } = Select

const DynamicFormComponent = ({ value = [], onChange }) => {
	const [inputs, setInputs] = useState(() => sortBy(value, 'sortId'))

	useEffect(() => {
		const sortedInputs = sortBy(value, 'sortId')
		setInputs(sortedInputs)
	}, [])

	const handleInputChange = (id, field, fieldValue) => {
		const newInputs = inputs.map((input) =>
			input.id === id ? { ...input, [field]: fieldValue } : input
		)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const handleNestedChange = (id, newValue) => {
		const newInputs = inputs.map((input) =>
			input.id === id ? { ...input, value: newValue } : input
		)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const addInput = () => {
		const newSortId =
			inputs.length > 0
				? Math.max(...inputs.map((input) => input.sortId)) + 1
				: 1
		const newInput = {
			type: 'text',
			label: '',
			value: '',
			sortId: newSortId,
			id: uuidv4()
		}
		const newInputs = [...inputs, newInput]
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const deleteInput = (id) => {
		const newInputs = inputs.filter((input) => input.id !== id)
		setInputs(newInputs)
		if (onChange) {
			onChange(newInputs)
		}
	}

	const renderInput = (input) => {
		switch (input.type) {
			case 'text':
				return (
					<Input
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'password':
				return (
					<Input.Password
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'textarea':
				return (
					<Input.TextArea
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'link':
				return (
					<Input
						type="url"
						value={input.value}
						onChange={(e) =>
							handleInputChange(input.id, 'value', e.target.value)
						}
					/>
				)
			case 'children':
				return (
					<DynamicFormComponent
						value={input.value}
						onChange={(newValue) =>
							handleNestedChange(input.id, newValue)
						}
					/>
				)
			default:
				return null
		}
	}

	return (
		<Space style={{ width: '100%' }} direction="vertical">
			{inputs.map((input) => (
				<Row key={input.id} gutter={16} align="middle">
					<Col span={8}>
						<Input
							placeholder="Label"
							value={input.label}
							onChange={(e) =>
								handleInputChange(
									input.id,
									'label',
									e.target.value
								)
							}
						/>
						<Button
							type="danger"
							onClick={() => deleteInput(input.id)}
						>
							Delete
						</Button>
					</Col>
					<Col span={16}>
						<Select
							value={input.type}
							onChange={(value) =>
								handleInputChange(input.id, 'type', value)
							}
						>
							<Option value="text">Text</Option>
							<Option value="password">Password</Option>
							<Option value="textarea">Textarea</Option>
							<Option value="link">Link</Option>
							<Option value="children">Children</Option>
						</Select>
						{renderInput(input)}
					</Col>
				</Row>
			))}
			<Row>
				<Col offset={8}>
					<Button type="dashed" onClick={addInput}>
						Add Input
					</Button>
				</Col>
			</Row>
		</Space>
	)
}

DynamicFormComponent.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func
}

export default DynamicFormComponent
