import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import { useStores } from '../../stores/MobXProvider'

const Category = ({ categoryId }) => {
	const { categories } = useStores()
	if (!categoryId) return '-'
	const cat = categories[categoryId].name || { name: '-' }
	return cat.name
}

Category.propTypes = {
	categoryId: PropTypes.number
}

export default observer(Category)
