import React from 'react'
import { Button, Col, Layout, Row, Switch } from 'antd'
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	UserOutlined
} from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'

import '../../App.css'
import NavBar from './NavBar'
import { ErrorBoundary } from '../Shared/ErrorBoundary'
import { headerStyle } from '../../design/app-style-config'
import { Link } from 'react-router-dom'
import { useStores } from '../../stores/MobXProvider'
import { appEnvironment } from '../../config'

const { Header } = Layout

const MainHeader = observer(({ collapsed, setCollapsed }) => {
	const { users, appType } = useStores()
	const { isLoggedIn, loggedInUser } = users

	const switchAppTypePermission =
		appEnvironment === 'dev'
			? true
			: isLoggedIn
			? loggedInUser?.isAdmin
			: false

	return (
		<Header style={headerStyle}>
			<Button
				type="text"
				icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
				onClick={() => setCollapsed(!collapsed)}
				style={{
					fontSize: '16px',
					width: 64,
					height: 64,
					color: '#000'
				}}
			/>
			<Row gutter={20}>
				{isLoggedIn && (
					<Col>
						<Link to={`/users/${loggedInUser.user_id}/data`}>
							<UserOutlined /> {loggedInUser.name}
						</Link>
					</Col>
				)}
				{isLoggedIn && switchAppTypePermission && (
					<Col>
						<Switch
							value={appType.appIsClient}
							checkedChildren="Client"
							unCheckedChildren="Team"
							defaultChecked
							onChange={appType.toggleAppType}
						/>
					</Col>
				)}
				<Col>
					<ErrorBoundary>
						<NavBar />
					</ErrorBoundary>
				</Col>
			</Row>
		</Header>
	)
})

MainHeader.propTypes = {
	collapsed: PropTypes.bool,
	setCollapsed: PropTypes.func
}

export default MainHeader
