import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'

import { useStores } from '../../stores/MobXProvider'
import { PageLoader } from '../../components/Shared'
import UserData from './UserData'
import UsersLst from './UsersLst'
import { actLoadUser } from '../../stores/users/actions'
const ProfileMain = () => {
	const navigate = useNavigate()

	const { users } = useStores()
	const { itmId } = useParams()
	const { loggedInUserId } = users
	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}
	}, [loggedInUserId, itmId])

	console.log('--- ProfileMain', loggedInUserId)
	return (
		<Routes>
			<Route
				path=":itmId/*"
				element={
					<PageLoader
						forceLoad={true}
						requireItm={(id) => {
							return users.requireUserById(id)
						}}
						loadItm={(id) => actLoadUser(users, id, true)}
						targetLst="users"
					/>
				}
			>
				<Route path="data" element={<UserData />} />
			</Route>
			<Route path="/" element={<UsersLst />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(ProfileMain)
