import { get } from 'lodash'
import { makeObservable, observable, computed, action } from 'mobx'
import { LstItmBase } from './lst-itm-base'

/**
 * Class representing a Request item, extending the base list item class LstItmBase.
 * Contains additional properties and methods specific to Request items.
 *
 * @class Request
 * @extends {LstItmBase}
 */
export class Request extends LstItmBase {
	request_id

	/**
	 * Creates an instance of Request.
	 * @param {string} id - The unique identifier for the Request item.
	 */
	constructor(id) {
		super(id)
		this.request_id = id

		makeObservable(this, {
			request_id: observable,
			forUserId: computed,
			setForUserId: action.bound
		})
	}
	get forUserId() {
		return get(this.full, ['forUserId'], null)
	}
	setForUserId(value) {
		this.chgInFull(['forUserId'], value)
	}
}
