// Styling for various layout components
const layoutStyle = {
	height: 'calc(100vh - 40px)',
	overflow: 'hidden',
	margin: 20,
	borderRadius: 40
}

const siderStyle = {
	textAlign: 'center',
	color: '#fff',
	backgroundColor: '#1b1d1f'
}

const headerStyle = {
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	background: '#fff',
	padding: '0 10px'
}

const contentStyle = {
	textAlign: 'left',
	minHeight: 120,
	color: '#333',
	backgroundColor: '#f3f7fb',
	padding: '10px 10px',
	overflow: 'scroll'
}

const footerStyle = {
	textAlign: 'center',
	backgroundColor: '#fff',
	color: '#000',
	padding: '10px'
}

export { layoutStyle, siderStyle, headerStyle, contentStyle, footerStyle }
