import { makeObservable, observable, action } from 'mobx'
import dayjs from 'dayjs'
/**
 * Class representing the Recurrence details of a Sprint item.
 */
export class Recurrence {
	type = ''
	startEnd = []
	times = []
	daysOfWeek = []
	dayOfMonth = ''
	months = []
	next = ''
	nextHistory = {}

	constructor({
		type = '',
		startEnd = [],
		times = [],
		daysOfWeek = [],
		dayOfMonth = '',
		months = [],
		next = '',
		nextHistory = {}
	} = {}) {
		this.type = type
		this.startEnd = startEnd
		this.times = times
		this.daysOfWeek = daysOfWeek
		this.dayOfMonth = dayOfMonth
		this.months = months
		this.next = next
		this.nextHistory = nextHistory

		makeObservable(this, {
			type: observable,
			startEnd: observable,
			times: observable,
			daysOfWeek: observable,
			dayOfMonth: observable,
			months: observable,
			next: observable,
			nextHistory: observable,

			setType: action.bound,
			setStartEnd: action.bound,
			setTimes: action.bound,
			setDaysOfWeek: action.bound,
			setDayOfMonth: action.bound,
			setMonths: action.bound,
			calculateNext: action.bound,
			completed: action.bound
		})
	}

	setType(type) {
		this.type = type
	}

	setStartEnd(startEnd) {
		this.startEnd = startEnd
	}

	setTimes(times) {
		this.times = times
	}

	setDaysOfWeek(daysOfWeek) {
		this.daysOfWeek = daysOfWeek
	}

	setDayOfMonth(dayOfMonth) {
		this.dayOfMonth = dayOfMonth
	}

	setMonths(months) {
		this.months = months
	}

	completed(date) {
		this.nextHistory[date] = true
	}

	/*
	 * Generate all possible dates between the start and end date
	 * @param {dayjs}
	 * @param {dayjs}
	 * @returns {Array}
	 */

	generateNextDate(startDate, endDate) {
		const nextHistory = this.nextHistory
		const debug = true
		const endDayjs = endDate.clone()

		if (debug)
			console.warn('Generating next date:', {
				startDate,
				endDate,
				type: this.type
			})

		const isDateCompleted = (date) => {
			const dateTimestamp = dayjs(date).startOf('day').unix()
			if (debug)
				console.warn('Checking if date is completed:', {
					date,
					dateTimestamp,
					completed: nextHistory[dateTimestamp]
				})
			return nextHistory[dateTimestamp]
		}

		const getDatesForDay = (currentDate) => {
			if (
				(currentDate.isAfter(startDate) ||
					currentDate.isSame(startDate, 'day')) &&
				(currentDate.isBefore(endDate) ||
					currentDate.isSame(endDate, 'day'))
			) {
				return [currentDate]
			} else {
				return []
			}
		}

		const getDatesForWeek = (currentDate) => {
			let dates = []
			const endDayjs = endDate.clone() // Use the endDate defined in your context
			let weekDate = currentDate.clone()

			while (
				weekDate.isBefore(endDayjs) ||
				weekDate.isSame(endDayjs, 'day')
			) {
				this.daysOfWeek.forEach((weekDay) => {
					for (let i = 0; i < 7; i++) {
						const date = weekDate
							.clone()
							.startOf('week')
							.add(i, 'day')
						if (
							date.format('dddd').toLowerCase() === weekDay &&
							(date.isAfter(startDate) ||
								date.isSame(startDate, 'day')) &&
							(date.isBefore(endDayjs) ||
								date.isSame(endDayjs, 'day'))
						) {
							dates.push(date)
						}
					}
				})

				if (dates.length > 0) {
					break
				}

				weekDate = weekDate.add(1, 'week')
			}

			dates.sort((a, b) => a.unix() - b.unix())
			return dates
		}

		const getDatesForMonth = (currentDate) => {
			let dates = []
			const daysInMonth = currentDate.daysInMonth()
			const dayOfMonth = this.dayOfMonth
			const monthStart = currentDate.startOf('month')

			if (debug)
				console.warn('Analyzing month:', currentDate.format('MM-YYYY'))

			if (dayOfMonth === 'first') {
				if (debug)
					console.warn(
						'Adding first day of month:',
						monthStart.toDate()
					)
				dates.push(monthStart)
			}
			if (dayOfMonth === 'last') {
				const lastDay = currentDate.date(daysInMonth)
				if (debug)
					console.warn('Adding last day of month:', lastDay.toDate())
				dates.push(lastDay)
			}
			if (dayOfMonth === 'first-work-day') {
				for (let i = 1; i <= daysInMonth; i++) {
					if (isWorkDay(currentDate.date(i))) {
						if (debug)
							console.warn(
								'Adding first work day of month:',
								currentDate.date(i).toDate()
							)
						dates.push(currentDate.date(i))
						break
					}
				}
			}
			if (dayOfMonth === 'last-work-day') {
				const lastWorkDay = findLastWorkDayOfMonth(currentDate)
				if (debug)
					console.warn(
						'Adding last work day of month:',
						lastWorkDay.toDate()
					)
				dates.push(lastWorkDay)
			}

			const firstMatch = dayOfMonth.match(/^first-(\w+)$/)
			if (firstMatch) {
				const firstWeekday = findFirstWeekdayOfMonth(
					currentDate,
					firstMatch[1]
				)
				if (debug)
					console.warn(
						`Adding first ${firstMatch[1]} of month:`,
						firstWeekday.toDate()
					)
				dates.push(firstWeekday)
			}

			const lastMatch = dayOfMonth.match(/^last-(\w+)$/)
			if (lastMatch) {
				const lastWeekday = findLastWeekdayOfMonth(
					currentDate,
					lastMatch[1]
				)
				if (debug)
					console.warn(
						`Adding last ${lastMatch[1]} of month:`,
						lastWeekday.toDate()
					)
				dates.push(lastWeekday)
			}

			const numericDay = parseInt(dayOfMonth, 10)
			if (
				!isNaN(numericDay) &&
				numericDay >= 1 &&
				numericDay <= daysInMonth
			) {
				if (debug)
					console.warn(
						'Adding specific day of month:',
						currentDate.date(numericDay).toDate()
					)
				dates.push(currentDate.date(numericDay))
			}

			dates = dates.filter(
				(date) =>
					(date.isBefore(endDayjs) || date.isSame(endDayjs, 'day')) &&
					(date.isAfter(startDate) || date.isSame(startDate, 'day'))
			)
			dates.sort((a, b) => a.unix() - b.unix())

			if (debug)
				console.warn(
					'Potential dates for month:',
					currentDate.month() + 1,
					dates
				)

			return dates
		}

		const getDatesForYear = (currentDate) => {
			let dates = []
			const originalYear = currentDate.year()

			this.months.forEach((month) => {
				// Set the currentDate to the specified month and original year
				const dateInMonth = currentDate
					.clone()
					.month(month)
					.year(originalYear)
				dates = dates.concat(getDatesForMonth(dateInMonth))
			})

			// Filter out dates outside the range of startDate and endDate
			dates = dates.filter(
				(date) =>
					(date.isBefore(endDayjs) || date.isSame(endDayjs, 'day')) &&
					(date.isAfter(startDate) || date.isSame(startDate, 'day'))
			)

			dates.sort((a, b) => a.unix() - b.unix())

			return dates
		}

		const findFirstWeekdayOfMonth = (currentDate, weekday) => {
			let firstDay = currentDate.startOf('month')
			while (firstDay.month() === currentDate.month()) {
				if (firstDay.format('dddd').toLowerCase() === weekday) {
					return firstDay
				}
				firstDay = firstDay.add(1, 'day')
			}
			return null
		}

		const findLastWeekdayOfMonth = (currentDate, weekday) => {
			let lastDay = currentDate.endOf('month')
			while (lastDay.month() === currentDate.month()) {
				if (lastDay.format('dddd').toLowerCase() === weekday) {
					return lastDay
				}
				lastDay = lastDay.subtract(1, 'day')
			}
			return null
		}

		const findLastWorkDayOfMonth = (currentDate) => {
			let lastWorkDay = currentDate.endOf('month')
			while (
				!isWorkDay(lastWorkDay) &&
				lastWorkDay.month() === currentDate.month()
			) {
				lastWorkDay = lastWorkDay.subtract(1, 'day')
			}
			return lastWorkDay
		}

		let nextDate = null
		let currentDate = startDate.clone().startOf('month')

		while (
			currentDate.isBefore(endDayjs) ||
			currentDate.isSame(endDayjs, 'day')
		) {
			let potentialDates = []

			switch (this.type) {
				case 'day':
					potentialDates = getDatesForDay(currentDate)
					currentDate = currentDate.add(1, 'day')
					break
				case 'week':
					potentialDates = getDatesForWeek(currentDate)
					currentDate = currentDate.add(1, 'week')
					break
				case 'month':
					potentialDates = getDatesForMonth(currentDate)
					currentDate = currentDate.add(1, 'month').startOf('month')
					break
				case 'year':
					potentialDates = getDatesForYear(currentDate)
					currentDate = currentDate.add(1, 'year').startOf('year')
					break
				default:
					console.error('Unsupported recurrence type:', this.type)
					return null
			}

			for (const date of potentialDates) {
				if (!isDateCompleted(date)) {
					console.warn('Date not completed:', date.toDate())
					nextDate = date
					break
				}
			}
			if (nextDate) break
		}

		if (debug)
			console.warn(
				'Next date found:',
				nextDate ? nextDate.toDate() : null
			)
		return nextDate ? nextDate.toDate() : null // No matching date found

		function isWorkDay(date) {
			const day = date.day()
			return day >= 1 && day <= 5
		}
	}

	calculateNext(onCompletion) {
		const debug = true

		if (!this?.startEnd?.length > 0) {
			this.next = ''
			if (debug) console.warn('No start/end dates provided')
			return
		}

		if (this.startEnd.length === 1 && this.next === '') {
			this.next = dayjs.unix(this.startEnd[0]).startOf('day').unix()
			if (debug) console.warn('Next date (single):', this.next)
			return
		}

		const startDate = dayjs.unix(this.startEnd[0])
		const endDate = dayjs.unix(this.startEnd[1])

		const nextDate = this.generateNextDate(startDate, endDate)

		this.next = nextDate ? dayjs(nextDate).startOf('day').unix() : null

		if (!this.next) {
			console.error('No next date found')
			// this means the recurrence is completed, so we need to mark it as such. Set progress to completed
			this.next = ''
			if (onCompletion) {
				onCompletion()
			}
			return
		}
		if (debug) console.warn('Next date (range):', this.next)
	}
}
