import { LstTypeBase } from './cls-lst-type-base'
import { makeObservable } from 'mobx'
export class Platform {
	constructor(data) {
		// Initialize Platform properties from data
		Object.assign(this, data)
	}
}

export class Platforms extends LstTypeBase {
	constructor(list = []) {
		// Pass the Platform class and list to the parent class constructor
		super(Platform, list)

		makeObservable(this, {
			// Add properties here if Platforms has specific properties
		})
	}
}
