import React, { useState } from 'react'
import { Button, List, Modal, Form, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import ProgressInput from './ProgressInput'
import { LstTypeBase } from '../../modules/cls/cls-lst-type-base'
import { v4 as uuidv4 } from 'uuid'

import { useStores } from '../../stores/MobXProvider'
const { TextArea } = Input

const EditItem = observer(
	({
		item,
		onSaveItem,
		onCancel,
		additionalEditSection,
		phaseTwoLimited
	}) => {
		const { users } = useStores()
		const { loggedInUser } = users
		const isAdmin = loggedInUser.isAdmin

		const [isModalVisible, setIsModalVisible] = useState(true)

		// if item.id does not exist, it is a new item and we need to generate a uuid

		const editingItem = item || {}
		if (!item) {
			editingItem.id = uuidv4()
			editingItem.status = 'draft'
		}

		const [form] = Form.useForm()
		const status = Form.useWatch('status', form)

		const isDisabled = !isAdmin ? !(status === 'draft') : false

		console.log('editingItem', editingItem)
		return (
			<Modal
				title={item ? 'Edit item' : 'Add item'}
				open={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false)
					onCancel() // Ensure modal close state is updated in parent
				}}
				footer={null}
			>
				<Form
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={editingItem}
					onFinish={onSaveItem}
					form={form}
					disabled={isDisabled}
				>
					<Form.Item name="id">
						<Input type="hidden" />
					</Form.Item>
					<Form.Item
						label="Status"
						name="status"
						rules={[
							{ required: true, message: 'Please select status' }
						]}
					>
						<ProgressInput phaseTwoLimited={phaseTwoLimited} />
					</Form.Item>
					<Form.Item
						label="Name"
						name="name"
						rules={[
							{ required: true, message: 'Please enter name' }
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={[
							{
								required: false,
								message: 'Please enter description'
							}
						]}
					>
						<TextArea rows={4} showCount />
					</Form.Item>

					{/* Conditionally render additional section */}
					{additionalEditSection && additionalEditSection()}

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button
							disabled={false}
							type="primary"
							htmlType="submit"
							style={{ width: '100%' }}
						>
							{item ? 'Update' : 'Add'}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
)

/*
* LstTypeInputBase is a generic component for displaying and editing a list of items.
* @param {UseLstClass} - The class to use for the list of items.
* @param {value} - The list of items to display and edit.
* @param {onChange} - The function to call when the list of items changes.
* @param {disabled} - Whether the component is disabled.

* - Additional props:
* @param {displayDescription} - Whether to display the description of each item.
* @param {phaseTwoLimited} - Whether to limit the status to phase two values.
* @param {additionalEditSection} - A function that returns additional edit form elements.
* @returns {JSX.Element} - The LstTypeInputBase component.

*/
const LstTypeInputBase = observer(
	({ UseLstClass = LstTypeBase, value, onChange, ...props }) => {
		const { disabled } = props

		const items = new UseLstClass(value || [])

		const [isModalVisible, setIsModalVisible] = useState(false)
		const [editingItem, setEditingItem] = useState(null)

		const showModal = () => {
			setEditingItem(null)
			setIsModalVisible(true)
		}

		const save = () => {
			onChange(items.prepareForSave())
		}

		const onSaveItem = (values) => {
			if (editingItem) {
				items.updateItem(values)
			} else {
				items.addItem(values)
			}
			save()
			setIsModalVisible(false)
		}

		const handleEdit = (id) => {
			const item = items.getItemById(id)
			setEditingItem(item)
			setIsModalVisible(true)
		}

		const handleDelete = (id) => {
			items.deleteItem(id)
			save()
		}

		return (
			<>
				<List
					header={
						!disabled && (
							<Button type="dashed" onClick={showModal}>
								Add
							</Button>
						)
					}
					bordered
					dataSource={items.list}
					renderItem={(item) => (
						<List.Item
							actions={
								!disabled
									? [
											<Button
												key={item.id + 'edit'}
												type="link"
												onClick={() =>
													handleEdit(item.id)
												}
											>
												Edit
											</Button>,
											<Button
												key={item.id + 'delete'}
												type="link"
												onClick={() =>
													handleDelete(item.id)
												}
											>
												Delete
											</Button>
									  ]
									: []
							}
						>
							{`${item.name} - ${item.status}`}
							{props?.displayDescription && item?.description && (
								<div
									style={{
										fontSize: 12,
										color: '#7d7d7d',
										margin: '0 0 0 10px',
										padding: 0
									}}
								>
									{item.description}
								</div>
							)}
						</List.Item>
					)}
				/>
				{isModalVisible && (
					<EditItem
						item={editingItem}
						onSaveItem={onSaveItem}
						onCancel={() => setIsModalVisible(false)}
						phaseTwoLimited={props?.phaseTwoLimited}
						additionalEditSection={
							props?.additionalEditSection || null
						}
					/>
				)}
			</>
		)
	}
)

export default LstTypeInputBase
