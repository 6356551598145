import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { actLoadSprint, requireSprintById } from '../../stores/sprints/actions'
import { useStores } from '../../stores/MobXProvider'

import { PageLoader } from '../../components/Shared'

import SprintsLst from './SprintsLst'
import SprintData from './SprintData'

const SprintsMain = () => {
	const navigate = useNavigate()

	const { users } = useStores()
	const { loggedInUserId } = users
	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}
	}, [loggedInUserId])

	return (
		<Routes>
			<Route
				path=":itmId/*"
				element={
					<PageLoader
						forceLoad={true}
						requireItm={(id) => {
							return requireSprintById(id)
						}}
						loadItm={(id) => actLoadSprint(id)}
						targetLst="sprints"
					/>
				}
			>
				<Route path="data" element={<SprintData />} />
				<Route path="view" element={<SprintData viewOnly={true} />} />
			</Route>
			<Route path="/" element={<SprintsLst />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(SprintsMain)
