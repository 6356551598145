import React from 'react'
import { List } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import ProgressInput from '../ProgressInput'
import { runInAction } from 'mobx'

export const SubtasksList = observer(
	({ getHistory, uiStore, _subtasks, _doSave }) => {
		return (
			_subtasks.list.length > 0 && (
				<List
					bordered
					dataSource={_subtasks.list}
					renderItem={(subtask) => (
						<List.Item
							actions={[
								<ProgressInput
									key={subtask.id + 'status'}
									value={subtask.status}
									onChange={(value) => {
										_doSave(subtask.id, value)
									}}
								/>,
								<InfoCircleOutlined
									key="info"
									onClick={() => {
										if (!!getHistory) {
											getHistory(subtask.id).then(
												(item) => {
													runInAction(() => {
														uiStore.setModal(
															'history-modal',
															{
																visible: true,
																item
															}
														)
													})
												}
											)
										}
									}}
								/>
							]}
						>
							{subtask.name}
						</List.Item>
					)}
				/>
			)
		)
	}
)
SubtasksList.propTypes = {
	getHistory: PropTypes.func,
	_subtasks: PropTypes.object.isRequired,
	_doSave: PropTypes.func.isRequired
}
