import { observer } from 'mobx-react-lite'
import React from 'react'
import { Space, Form, DatePicker, Select, Checkbox, TreeSelect } from 'antd'
import dayjs from 'dayjs'
import TimesRange from '../TimesRange'
import Title from 'antd/es/typography/Title'
import { recurrenceLabels } from './labels'
import { GeneralTourTitle } from '../../Shared/GeneralComponents'
import { getTourArrayByKeys } from '../../../stores/tour/config'
import { useStores } from '../../../stores/MobXProvider'
import { InfoCircleOutlined } from '@ant-design/icons'

const DaysOfMonth = observer(({ value, onChange }) => {
	return (
		<TreeSelect
			value={value}
			onChange={onChange}
			style={{
				width: '100%'
			}}
			dropdownStyle={{
				maxHeight: 400,
				overflow: 'auto'
			}}
			treeData={[
				{
					title: 'First',
					value: 'first-group',
					disabled: true,
					children: [
						{
							title: 'First day of month',
							value: 'first'
						},
						{
							title: 'First work day (Monday-Friday)',
							value: 'first-work-day'
						},
						{
							title: 'Week days',
							value: 'first-week-days',
							disabled: true,
							children: [
								{
									title: 'First monday',
									value: 'first-monday'
								},
								{
									title: 'First tuesday',
									value: 'first-tuesday'
								},
								{
									title: 'First wednesday',
									value: 'first-wednesday'
								},
								{
									title: 'First thursday',
									value: 'first-thursday'
								},
								{
									title: 'First friday',
									value: 'first-friday'
								},
								{
									title: 'First saturday',
									value: 'first-saturday'
								},
								{
									title: 'First sunday',
									value: 'first-sunday'
								}
							]
						}
					]
				},
				{
					title: 'Last',
					value: 'last-group',
					disabled: true,
					children: [
						{
							title: 'Last day of month',
							value: 'last'
						},
						{
							title: 'Last work day (Monday-Friday)',
							value: 'last-work-day'
						},
						{
							title: 'Week days',
							value: 'last-week-days',
							disabled: true,
							children: [
								{
									title: 'Last monday',
									value: 'last-monday'
								},
								{
									title: 'Last tuesday',
									value: 'last-tuesday'
								},
								{
									title: 'Last wednesday',
									value: 'last-wednesday'
								},
								{
									title: 'Last thursday',
									value: 'last-thursday'
								},
								{
									title: 'Last friday',
									value: 'last-friday'
								},
								{
									title: 'Last saturday',
									value: 'last-saturday'
								},
								{
									title: 'Last sunday',
									value: 'last-sunday'
								}
							]
						}
					]
				}
			]}
			placeholder="Please select"
			treeDefaultExpandAll
		/>
	)
})

export const RecurrenceFormItems = observer(({ form }) => {
	const { tourStore } = useStores()

	const recurrenceTypeTrack = Form.useWatch('recurrenceType', form)
	const recurrenceStartEndTrack = Form.useWatch('recurrenceStartEnd', form)

	const weekDaysOptions = [
		{ label: 'Monday', value: 'monday' },
		{ label: 'Tuesday', value: 'tuesday' },
		{ label: 'Wednesday', value: 'wednesday' },
		{ label: 'Thursday', value: 'thursday' },
		{ label: 'Friday', value: 'friday' },
		{ label: 'Saturday', value: 'saturday' },
		{ label: 'Sunday', value: 'sunday' }
	]

	// We start months from 0, so we can match dayJs month numbers
	const allMonthsOptions = [
		{ label: 'January', value: '0' },
		{ label: 'February', value: '1' },
		{ label: 'March', value: '2' },
		{ label: 'April', value: '3' },
		{ label: 'May', value: '4' },
		{ label: 'June', value: '5' },
		{ label: 'July', value: '6' },
		{ label: 'August', value: '7' },
		{ label: 'September', value: '8' },
		{ label: 'October', value: '9' },
		{ label: 'November', value: '10' },
		{ label: 'December', value: '11' }
	]

	const filteredMonthsOptions = recurrenceStartEndTrack
		? allMonthsOptions.filter((month) => {
				const start = dayjs(recurrenceStartEndTrack[0])
				const end = dayjs(recurrenceStartEndTrack[1])
				const monthIndex = allMonthsOptions.findIndex(
					(opt) => opt.value === month.value
				)
				const startMonthIndex = start.month()
				const endMonthIndex = end.month()
				const startYear = start.year()
				const endYear = end.year()

				// If start and end are in the same year
				if (startYear === endYear) {
					return (
						monthIndex >= startMonthIndex &&
						monthIndex <= endMonthIndex
					)
				}

				// If the current month is in the start year
				if (
					start.year() === startYear &&
					monthIndex >= startMonthIndex
				) {
					return true
				}

				// If the current month is in the end year
				if (end.year() === endYear && monthIndex <= endMonthIndex) {
					return true
				}

				// If the date range spans multiple years and the current month is in between
				if (startYear < endYear) {
					return true
				}

				return false
		  })
		: allMonthsOptions

	// eslint-disable-next-line react/prop-types
	const UseSingleDateOrRange = ({ recurrenceType, ...props }) => {
		// eslint-disable-next-line react/prop-types
		const { value, onChange, ...rest } = props
		return recurrenceType === 'deadline' ? (
			<DatePicker
				{...rest}
				value={value && value[0]}
				onChange={(value) => {
					form.setFieldsValue({
						recurrenceStartEnd: [value]
					})
					onChange(value)
				}}
			/>
		) : (
			<DatePicker.RangePicker
				allowEmpty
				value={value}
				onChange={(value) => {
					// however, end date cant be same day as start date
					const [start, end] = value || []
					if (start && end && start.isSame(end, 'day')) {
						value[1] = end.add(1, 'day')
						alert('End date cannot be the same as start date!')
					} else if (start && !end) {
						value[1] = start.add(1, 'day')
					}

					// Fallback and recheck the start and end dates, make sure they are not the same day
					const [startDay, endDay] = value || []
					if (startDay && endDay && startDay.isSame(endDay, 'day')) {
						alert('End date cannot be the same as start date!')
						value[1] = startDay.add(1, 'day')
					}
					form.setFieldsValue({
						recurrenceStartEnd: value || []
					})
					onChange(value || [])
				}}
				{...rest}
			/>
		)
	}

	/*
	 * Tour Reccurence
	 * This is a tour for the recurrence fields. Manually added to the tour due to possible scenario were list item might not have all recurrence types.
	 */
	let tourRecurrence = []

	if (recurrenceTypeTrack === 'week') {
		tourRecurrence = getTourArrayByKeys('recurrence', [
			'recurrencedaysofweek'
		])
	} else if (recurrenceTypeTrack === 'month') {
		tourRecurrence = getTourArrayByKeys('recurrence', [
			'recurrencedayofmonth'
		])
	} else if (recurrenceTypeTrack == 'year') {
		tourRecurrence = getTourArrayByKeys('recurrence', [
			'recurrencemonths',
			'recurrencedayofmonth'
		])
	}

	const startTour = () => {
		tourStore.startTour('Recurrence-tour', [
			// Recurrence
			...getTourArrayByKeys('recurrence', [
				'section',
				'recurrencenext',
				'recurrencetype',
				'recurrencestartend'
			]),
			...tourRecurrence,
			...getTourArrayByKeys('recurrence', ['recurrencetimes'])
		])
	}

	return (
		<Space
			id="target-recurrenceformitems"
			direction="vertical"
			style={{ width: '100%' }}
		>
			<GeneralTourTitle
				align="end"
				titleProps={{
					text: 'Recurrence',
					h: Title,
					level: 5
				}}
				buttonProps={{
					onClick: startTour,
					disabled: false,
					style: { marginBottom: 4 }
				}}
			/>
			<div id="target-recurrenceformitems-recurrencenext">
				<Form.Item
					label="Recurrence next iteration"
					//tooltip="Next iteration of the sprint."
					tooltip={{
						title: 'Next iteration of the sprint.',
						icon: <InfoCircleOutlined />
					}}
					name="recurrenceNext"
				>
					<DatePicker
						style={{ width: '100%' }}
						disabled={true}
						format="DD/MM/YYYY"
					/>
				</Form.Item>
			</div>
			<Space>
				<div id="target-recurrenceformitems-recurrencetype">
					<Form.Item
						label="Recurrence"
						//tooltip="Recurrence of the sprint."
						tooltip={{
							title: 'Recurrence of the sprint.',
							icon: <InfoCircleOutlined />
						}}
						name="recurrenceType"
						rules={[
							{
								required: true,
								message: 'Please provide a recurrence!'
							}
						]}
					>
						<Select
							placeholder="Select a recurrence"
							allowClear
							options={[
								...Object.keys(recurrenceLabels).map((key) => ({
									label: recurrenceLabels[key],
									value: key
								}))
							]}
							onChange={() => {
								form.setFieldsValue({
									recurrenceStartEnd: [],
									recurrenceMonthsTrack: [],
									recurrenceDaysOfWeek: [],
									recurrenceDayOfMonth: '',
									recurrenceMonths: [],
									recurrenceTimes: []
								})
							}}
						/>
					</Form.Item>
				</div>
				<div id="target-recurrenceformitems-recurrencestartend">
					<Form.Item
						label={
							recurrenceTypeTrack !== 'deadline'
								? 'Start/End'
								: 'Deadline'
						}
						//tooltip="Start and end date of the sprint."
						tooltip={{
							title: 'Start and end date of the sprint.',
							icon: <InfoCircleOutlined />
						}}
						name="recurrenceStartEnd"
						rules={[
							{
								required: true,
								message: 'Please provide a start/end date!'
							}
						]}
					>
						<UseSingleDateOrRange
							recurrenceType={recurrenceTypeTrack}
							style={{ width: '100%' }}
							presets={
								recurrenceTypeTrack !== 'deadline' && [
									{
										label: (
											<span aria-label="Current Time to End of Day">
												Now ~ EOD
											</span>
										),
										value: () => [
											dayjs(),
											dayjs().endOf('day')
										] // 5.8.0+ support function
									},
									{
										label: (
											<span aria-label="Current Time to End of Week">
												Now ~ EOW
											</span>
										),
										value: () => [
											dayjs(),
											dayjs().endOf('week')
										]
									},
									{
										label: (
											<span aria-label="Current Time to End of Month">
												Now ~ EOM
											</span>
										),
										value: () => [
											dayjs(),
											dayjs().endOf('month')
										]
									},
									{
										label: (
											<span aria-label="Current Time to End of Year">
												Now +30days
											</span>
										),
										value: () => [
											dayjs(),
											dayjs().add(30, 'day')
										]
									},
									{
										label: (
											<span aria-label="Current Time to End of Year">
												Now ~ EOY
											</span>
										),
										value: () => [
											dayjs(),
											dayjs().endOf('year')
										]
									}
								]
							}
							allowClear
							format="DD/MM/YYYY"
							minDate={dayjs().startOf('day')}
							onChange={() => {
								form.setFieldsValue({
									recurrenceMonths: [],
									recurrenceDaysOfWeek: [],
									recurrenceDayOfMonth: ''
								})
							}}
							showTime={recurrenceTypeTrack === 'deadline'}
							picker={
								recurrenceTypeTrack === 'deadline'
									? 'date'
									: recurrenceTypeTrack === 'day'
									? 'date'
									: recurrenceTypeTrack === 'week'
									? 'week'
									: recurrenceTypeTrack === 'month'
									? 'month'
									: recurrenceTypeTrack === 'year' && 'year'
							}
						/>
					</Form.Item>
				</div>
			</Space>
			{recurrenceTypeTrack !== 'deadline' && (
				<>
					{recurrenceTypeTrack === 'week' && (
						<div id="target-recurrenceformitems-recurrencedaysofweek">
							<Form.Item
								label="Days of the Week"
								//tooltip="Select the days of the week when this sprint should recur."
								tooltip={{
									title: 'Select the days of the week when this sprint should recur.',
									icon: <InfoCircleOutlined />
								}}
								name="recurrenceDaysOfWeek"
							>
								<Checkbox.Group options={weekDaysOptions} />
							</Form.Item>
						</div>
					)}
					{recurrenceTypeTrack === 'month' && (
						<div id="target-recurrenceformitems-recurrencedayofmonth">
							<Form.Item
								label="Day of the Month"
								//tooltip="Select the day of the month when this sprint should recur."
								tooltip={{
									title: 'Select the day of the month when this sprint should recur.',
									icon: <InfoCircleOutlined />
								}}
								name="recurrenceDayOfMonth"
								rules={[
									{
										required: true,
										message:
											'Please provide days of the month!'
									}
								]}
							>
								<DaysOfMonth />
							</Form.Item>
						</div>
					)}
					{recurrenceTypeTrack === 'year' && (
						<>
							<div id="target-recurrenceformitems-recurrencemonths">
								<Form.Item
									label="Months"
									//tooltip="Select the months when this sprint should recur."
									tooltip={{
										title: 'Select the months when this sprint should recur.',
										icon: <InfoCircleOutlined />
									}}
									name="recurrenceMonths"
									rules={[
										{
											required: true,
											message: 'Please provide months!'
										}
									]}
								>
									<Checkbox.Group
										options={filteredMonthsOptions}
									/>
								</Form.Item>
							</div>
							<div id="target-recurrenceformitems-recurrencedayofmonth">
								<Form.Item
									label="Day of the Month"
									//tooltip="Select the day of the month when this sprint should recur."
									tooltip={{
										title: 'Select the day of the month when this sprint should recur.',
										icon: <InfoCircleOutlined />
									}}
									name="recurrenceDayOfMonth"
									rules={[
										{
											required: true,
											message:
												'Please provide days of the month!'
										}
									]}
								>
									<DaysOfMonth />
								</Form.Item>
							</div>
						</>
					)}
					<div id="target-recurrenceformitems-recurrencetimes">
						<Form.Item
							label="Times"
							//tooltip="Select the times of day between start and end of this sprint."
							tooltip={{
								title: 'Select the times of day between start and end of this sprint',
								icon: <InfoCircleOutlined />
							}}
							name="recurrenceTimes"
						>
							<TimesRange />
						</Form.Item>
					</div>
				</>
			)}
		</Space>
	)
})
