import React, { useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import { register } from '../modules/api-user'
import { useStores } from '../stores/MobXProvider'
import { startError } from '../stores/work-progress/actions'

const { Text } = Typography // Import Typography for text styling

const UserRegister = () => {
	const { users } = useStores()

	const [form] = Form.useForm()
	const navigate = useNavigate()

	const curentUser = users.loggedInUserId
	useEffect(() => {
		if (curentUser) {
			// Redirect to dashboard if user is already logged in
			navigate('/dashboard')
		}
	}, [curentUser, navigate])

	const onFinish = async (values) => {
		const newId = uuidv4()
		values.user_id = newId

		try {
			register(values)
				.then((response) => {
					if (response.status === 'success') {
						const userId = response.user.user_id

						users.setLoggedInUser(userId, response.apiToken)
						const user = users.requireUserById(userId)
						user.updateFromServer(response.user)
						navigate('/dashboard')
					} else {
						startError(response.message)
					}
				})
				.catch((error) => {
					console.error('Failed to fetch user info:', error)
				})
		} catch (error) {
			alert('Registration failed, please try again later.')
			console.error('Registration error:', error)
		}
	}

	return (
		<Form form={form} name="register" onFinish={onFinish} layout="vertical">
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{
						required: true,
						message: 'Please input your Email!',
						type: 'email'
					}
				]}
			>
				<Input placeholder="Email" />
			</Form.Item>
			<Form.Item
				name="password"
				label="Password"
				rules={[
					{ required: true, message: 'Please input your Password!' }
				]}
			>
				<Input.Password placeholder="Password" />
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					Register
				</Button>
			</Form.Item>
			<Form.Item>
				<Text style={{ display: 'block', textAlign: 'center' }}>
					Already have an account? <Link to="/login">Login now</Link>
				</Text>
			</Form.Item>
		</Form>
	)
}

export default observer(UserRegister)
