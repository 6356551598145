import { Button } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
const SubmitButton = ({ children, loading, ...rest }) => {
	const defaultStyle = {
		marginTop: 25,
		background: 'unset',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#4846f4',
		color: '#4846f4',
		boxShadow: 'unset'
	}
	return (
		<Button
			type="primary"
			htmlType="submit"
			loading={loading}
			{...rest}
			style={
				rest.style ? { ...rest.style, ...defaultStyle } : defaultStyle
			}
		>
			{children}
		</Button>
	)
}

SubmitButton.propTypes = {
	children: PropTypes.node.isRequired,
	loading: PropTypes.bool
}
export default SubmitButton
