import { runInAction } from 'mobx'
import rootStore from '../..' // Import the root store
import {
	createCheckoutSession,
	getAvailablePlans,
	getStripeStatus,
	searchCustomers
} from '../../../modules/api-stripe'
import { wrapWithLoading } from '../../general-actions'
import { loadStripe } from '@stripe/stripe-js'

const subscriptionsStore = rootStore.subscriptions
const usersStore = rootStore.users

// Directly define and wrap the function
const stripeGetCustomerIdByEmail = wrapWithLoading(
	async (userEmail) => {
		const { response } = await searchCustomers({ email: userEmail })
		if (!response?.data.length) {
			console.log('No customerId found for user:', userEmail)
			return null
		}
		return response.data[0].id
	},
	{ loadingMessage: 'Fetching Customer ID', checkAuthExpired: false }
)

/*
 *
 * @userEmail - required to fetch the customerId from Stripe server
 * @priceId - the plan id to purchase from Stripe (we have to manually add plans first in Stripe)
 * - requires stripePromise to be loaded, use your Stripe publishable key
 */
export const stripeCheckout = wrapWithLoading(
	async (userEmail, priceId) => {
		// Replace with your Stripe publishable key
		const stripePromise = loadStripe(
			'pk_test_51PQpo4HS85HKTLmvqLt2vulCIZXcoQR5bwaahDQTLx2R4vuIs7xSSFwhXyRD8KEYG1cWDAjQa95h9WmI7ExceQaO00GyMPkFOL'
		)

		const stripe = await stripePromise

		// If customer is not found, create a new customer
		const customerId = await stripeGetCustomerIdByEmail(userEmail)

		const response = await createCheckoutSession({
			customerId,
			email: userEmail,
			priceId
		})

		const sessionId = response.id
		const { error } = await stripe.redirectToCheckout({ sessionId })

		if (error) {
			console.error('Error redirecting to checkout', error)
			throw error
		}
	},
	{ loadingMessage: 'Creating Checkout Session' }
)

export const stripeLoadPlans = wrapWithLoading(
	async () => {
		const response = await getAvailablePlans()
		runInAction(() => {
			subscriptionsStore.setPlans(response)
		})
	},
	{ loadingMessage: 'Fetching Plans' }
)

export const stripeGetSubscriptionStatus = wrapWithLoading(
	async (userUid) => {
		const user = usersStore.requireUserById(userUid)

		// If customer is not found, will create a new customer
		const customerId = await stripeGetCustomerIdByEmail(user.email)
		if (!customerId) {
			return
		}
		const response = await getStripeStatus({ customerId })
		if (response.error) {
			throw new Error(
				response.error + ' - ' + JSON.stringify(response.details)
			)
		}
		runInAction(() => {
			user.setSubscriptions(response.subscriptions)
			usersStore.saveState()
		})
	},
	{ loadingMessage: 'Checking Subscription Status' }
)
