import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'

/*
 * columns: array of strings or function (components) example: ['name', 'email', 'phone']
 * data: array of objects with children array of objects with item and style
 */
const CustomTable = observer(({ columns, data, loading = false }) => {
	return (
		<table className="table-simple">
			{columns && (
				<thead>
					<tr>
						{columns.map((column, index) => {
							const columnItem =
								typeof column === 'object'
									? column.item
									: column
							return (
								<th key={index} style={column?.style || {}}>
									{typeof columnItem === 'function'
										? columnItem()
										: columnItem}
								</th>
							)
						})}
					</tr>
				</thead>
			)}
			<tbody>
				{loading ? (
					<tr>
						<td colSpan={columns.length || data[0].length}>
							Loading...
						</td>
					</tr>
				) : (
					data.map((row, index) => {
						const cells = Array.isArray(row)
							? row
							: row.children || []
						return (
							<tr key={index}>
								{cells.map((cell, index) => {
									if (!cell) return null
									if (typeof cell === 'string') {
										return <td key={index}>{cell}</td>
									} else if (typeof cell === 'function') {
										return <td key={index}>{cell()}</td>
									}
									return (
										<td
											key={index}
											style={cell?.style || {}}
										>
											{typeof cell.item === 'function'
												? cell.item()
												: cell.item}
										</td>
									)
								})}
							</tr>
						)
					})
				)}
			</tbody>
		</table>
	)
})

CustomTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array.isRequired,
	loading: PropTypes.bool
}

export default CustomTable
