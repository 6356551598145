import { defaultFilterOptions } from '../../components/Shared/Filter'

const tourInfo = {
	name: {
		title: 'Item name',
		description:
			'This name is used to identify the item in the system and used in search.',
		target: () => document.getElementById('target-info-name')
	},
	modified: {
		title: 'Last Modified',
		description:
			'Displays the date and time when the item was last updated.',
		target: () => document.getElementById('target-info-modified')
	}
}

const tourForm = {
	section: {
		title: 'Form Section',
		description:
			'This section contains all the fields required for submitting the form.',
		target: () => document.getElementById('target-form')
	},
	name: {
		title: 'Name Field',
		description: 'Enter the name of the item in this field.',
		target: () => document.getElementById('target-form-name')
	},
	category_id: {
		title: 'Category Field',
		description: 'Select the category this item belongs to.',
		target: () => document.getElementById('target-form-category_id')
	},
	user_id: {
		title: 'User Field',
		description: 'Assign a user responsible for this item.',
		target: () => document.getElementById('target-form-user_id')
	},
	platformIds: {
		title: 'Platform Field',
		description: 'Choose the platforms related to this item.',
		target: () => document.getElementById('target-form-platformIds')
	},
	progress: {
		title: 'Subtasks Field',
		description:
			'Track the progress of the item by adding and managing subtasks.',
		target: () => document.getElementById('target-form-progress')
	}
}

const tourFeatures = {
	section: {
		title: 'Features Section',
		description:
			'This section highlights various features available for the item.',
		target: () => document.getElementById('target-features')
	},
	recurrence: {
		title: 'Recurrence Feature',
		description:
			'This feature allows the item to recur at specified intervals.',
		target: () => document.getElementById('target-features-recurrence')
	},
	subtasks: {
		title: 'Subtasks Feature',
		description:
			'Manage and track progress by breaking down tasks into subtasks.',
		target: () => document.getElementById('target-features-subtasks')
	},
	comments: {
		title: 'Comments Feature',
		description: 'View and add comments related to this item.',
		target: () => document.getElementById('target-features-comments')
	}
}

const tourRecurrence = {
	section: {
		title: 'Recurrence Section',
		description:
			'This section allows you to configure the recurrence settings for the sprint.',
		target: () => document.getElementById('target-recurrenceformitems')
	},
	recurrencenext: {
		title: 'Next Recurrence',
		description: 'Shows the date for the next iteration of the recurrence.',
		target: () =>
			document.getElementById('target-recurrenceformitems-recurrencenext')
	},
	recurrencetype: {
		title: 'Recurrence Type',
		description:
			'Defines the type of recurrence (e.g., daily, weekly, monthly).',
		target: () =>
			document.getElementById('target-recurrenceformitems-recurrencetype')
	},
	recurrencestartend: {
		title: 'Recurrence Start and End',
		description: 'Configure the start/end dates for the recurrence.',
		target: () =>
			document.getElementById(
				'target-recurrenceformitems-recurrencestartend'
			)
	},
	recurrencetimes: {
		title: 'Recurrence Time of Day',
		description: 'Specify the time of day when the sprint should recur.',
		target: () =>
			document.getElementById(
				'target-recurrenceformitems-recurrencetimes'
			)
	},
	recurrencedaysofweek: {
		title: 'Recurrence Days of the Week',
		description:
			'Select the days of the week on which the sprint should recur.',
		target: () =>
			document.getElementById(
				'target-recurrenceformitems-recurrencedaysofweek'
			)
	},
	recurrencedayofmonth: {
		title: 'Recurrence Day of the Month',
		description:
			'Specify the day of the month when the sprint should recur.',
		target: () =>
			document.getElementById(
				'target-recurrenceformitems-recurrencedayofmonth'
			)
	},
	recurrencemonths: {
		title: 'Recurrence Month',
		description: 'Specify the months during which the sprint should recur.',
		target: () =>
			document.getElementById(
				'target-recurrenceformitems-recurrencemonths'
			)
	}
}

const _filterOptionsLabels = defaultFilterOptions
	.filter((option) => option.value)
	.map((option) => option.label)

const tourFilter = {
	section: {
		title: 'Filter Section',
		description:
			'This section allows you to filter items based on specific criteria. Each change will trigger a new database query based on the selected filters.',
		target: () => document.getElementById('target-filter')
	},
	search: {
		title: 'Search Functionality',
		description: 'Use this input to search for items by their title.',
		target: () => document.getElementById('target-filter-search')
	},
	progress: {
		title: 'Progress Filter',
		description: `Filter items based on their progress status. (Example: ${_filterOptionsLabels.join(
			', '
		)})`,
		target: () => document.getElementById('target-filter-progress')
	},
	category: {
		title: 'Category Filter',
		description:
			'Select a category to filter items that belong to that category.',
		target: () => document.getElementById('target-filter-category')
	},
	delete: {
		title: 'Deleted Items Filter',
		description:
			'Filter to view or restore items that have been marked for deletion. A marked item will be permanently deleted after a set period. However it can be restored before the deletion period as a safety measure.',
		target: () => document.getElementById('target-filter-deleted')
	},
	new: {
		title: 'Add New Item',
		description: 'Click here to add a new item to the list.',
		target: () => document.getElementById('target-filter-addnew')
	}
}

const tourSprintLst = {
	section: {
		title: 'Sprint List',
		description: 'Organize and manage your sprints using this list.',
		target: () => document.getElementById('items-lst')
	},
	sprint: {
		title: 'Sprint Overview',
		description: 'View the sprint title and track its progress here.',
		target: () => document.getElementById('target-sprint')
	},
	info: {
		title: 'Sprint Information',
		description:
			'See details such as the assigned user(Client) and sprint category.',
		target: () => document.getElementById('target-info')
	},
	recurrence: {
		title: 'Sprint Recurrence',
		description: 'Review the recurrence settings for this sprint.',
		target: () => document.getElementById('target-recurrence')
	},
	actions: {
		title: 'Actions Column',
		description:
			'Perform actions like editing, deleting, and managing the sprint.',
		target: () => document.getElementById('target-actions')
	},
	itmProgress: {
		title: 'Sprint Progress',
		description: 'Track the progress of the sprint using this indicator.',
		target: () => document.getElementById('target-itm-progress')
	},
	itmLink: {
		title: 'Sprint View',
		description: 'Click the sprint title to view more details.',
		target: () => document.getElementById('target-itm-link')
	},
	itmUser: {
		title: 'Assigned User (Client)',
		description:
			'Sprint was assigned for this user(becoming the client). All users have an admin assigned by default or selected.',
		target: () => document.getElementById('target-itm-user')
	},
	itmCategory: {
		title: 'Sprint Category',
		description: 'View the category assigned to the sprint.',
		target: () => document.getElementById('target-itm-category')
	},
	itmRecurrence: {
		title: 'Recurrence Status',
		description: 'View the recurrence status for the sprint.',
		target: () => document.getElementById('target-itm-recurrence')
	},
	itmInfo: {
		title: 'Sprint additional Information',
		description:
			'View additional information about the sprint such as Info (Updated last, Created at/by, Assigned to) or Recurrence extra details.',
		target: () => document.getElementById('target-itm-info')
	},
	itmActions: {
		title: 'Sprint Actions',
		description:
			'Perform actions like editing, deleting or managing the sprint.',
		target: () => document.getElementById('target-itm-actions')
	}
}

const tour = {
	form: tourForm,
	features: tourFeatures,
	recurrence: tourRecurrence,
	info: tourInfo,
	sprintLst: tourSprintLst,
	filter: tourFilter
}

/**
 * listTarget is string, we need to target the object in this file
 *keys example: ['recurrence', 'subtasks', 'comments']
 */
const getTourArrayByKeys = (target, keys) => {
	if (keys.length === 1 && keys[0] === 'all') {
		// return all keys value as array
		return Object.keys(tour[target]).map((key) => tour[target][key])
	}
	return keys.map((key) => tour[target][key])
}

export { tour, getTourArrayByKeys }
