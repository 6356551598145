import React from 'react'
import { Space, Tag } from 'antd'
import { SwapOutlined } from '@ant-design/icons'
import { FormatedTimestamp } from './FormatedTimestamp'
import PropTypes from 'prop-types'
import { DATE_FORMAT } from '../../config'
const BetweenDates = ({ timestamps, formatedAs = DATE_FORMAT }) => {
	return (
		<Tag color="default">
			<Space align="center" size={3}>
				<span>
					<FormatedTimestamp
						timestamp={timestamps[0]}
						formatedAs={formatedAs}
					/>
				</span>
				<div style={{ display: 'flex' }}>
					<SwapOutlined
						style={{ fontSize: '8px', color: '#959595' }}
					/>
				</div>
				<span>
					<FormatedTimestamp
						timestamp={timestamps[1]}
						formatedAs={formatedAs}
					/>
				</span>
			</Space>
		</Tag>
	)
}
BetweenDates.propTypes = {
	timestamps: PropTypes.array,
	formatedAs: PropTypes.string
}

export default BetweenDates
