import { observer } from 'mobx-react-lite'
import React from 'react'
import PropTypes from 'prop-types'
/**
 * Props: LstItem, ids, children
 * @param {*} props
 * @returns <table> with <thead> and <tbody> elements
 * @LstTable
 */
const LstTable = ({ id, LstItem, store, className, children }) => {
	if (!LstItem) return null

	const { ids } = store
	const hasItems = ids?.length > 0
	const firstId = hasItems && ids[0]
	return (
		<div id={id} style={{ width: '100%' }}>
			{hasItems && (
				<table className={className}>
					{children && <thead>{children}</thead>}
					<tbody>
						{ids.map((id) => (
							<LstItem
								key={id}
								id={id}
								isFirst={firstId === id}
							/>
						))}
					</tbody>
				</table>
			)}
			{!hasItems && <div>No items</div>}
		</div>
	)
}
LstTable.propTypes = {
	id: PropTypes.string,
	LstItem: PropTypes.elementType.isRequired,
	store: PropTypes.object.isRequired,
	className: PropTypes.string,
	children: PropTypes.node
}
export default observer(LstTable)
