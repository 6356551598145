import { makeObservable, observable, action, computed, runInAction } from 'mobx'
class AppTypeStore {
	type = ''
	constructor(rootStore) {
		this.rootStore = rootStore
		makeObservable(this, {
			type: observable,
			appIs: computed,
			appIsClient: computed,
			appIsTeam: computed,

			setAppType: action.bound,
			toggleAppType: action.bound
		})

		// Load initial state from localStorage
		this.loadState()
	}
	/**
	 * @action
	 * @description Loads the state from localStorage.
	 */
	loadState() {
		const state = JSON.parse(localStorage.getItem('appType'))
		if (state) {
			this.setAppType(state)
		}
	}

	get appIsClient() {
		return this.type === 'client'
	}

	get appIsTeam() {
		return this.type === 'team'
	}

	get appIs() {
		return this.type
	}

	setAppType(value) {
		runInAction(() => {
			if (this.type !== value) {
				this.type = value
				localStorage.setItem('appType', JSON.stringify(value))
			}
		})
	}

	toggleAppType() {
		this.setAppType(this.type === 'client' ? 'team' : 'client')
	}
}

export default AppTypeStore
