import React, { Suspense, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Layout, Tour } from 'antd'
import { observer } from 'mobx-react-lite'
import './App.css'

// Stores
import rootStore from './stores'
import { MobXProvider } from './stores/MobXProvider'

// Design
import AppDesign from './design/AppDesign'
import { layoutStyle } from './design/app-style-config'

// App components
import Sidebar from './components/Layout/Sidebar'
import MainHeader from './components/Layout/Header'
import MainContent from './components/Layout/Content'
import MainFooter from './components/Layout/Footer'
import { APP_TYPE } from './config'

/**
 * Component that serves as a fallback UI during lazy loading.
 * @returns {JSX.Element} The fallback UI.
 */
const WaitFallback = () => {
	return <div>Please wait...</div>
}

/**
 * The main App component that contains the layout and routing for the application.
 * @returns {JSX.Element} The App component.
 */
function App() {
	// Sidebar collapsed state
	const [collapsed, setCollapsed] = useState(false)

	// Set the app type if it is not already set
	if (!rootStore.appType.type) {
		// Determine the app type based on the hostname
		const hostname = window.location.hostname

		if (hostname === APP_TYPE.client) {
			rootStore.appType.setAppType('client')
		} else if (hostname === APP_TYPE.team) {
			rootStore.appType.setAppType('team')
		} else {
			console.warn('hostname:', hostname, 'app type defined:: client')
			rootStore.appType.setAppType('client') // Default to client
		}
	}

	return (
		<BrowserRouter>
			<Suspense fallback={<WaitFallback />}>
				<MobXProvider rootStore={rootStore}>
					<AppDesign>
						<Layout style={layoutStyle}>
							<Sidebar
								collapsed={collapsed}
								setCollapsed={setCollapsed}
							/>
							<Layout>
								<MainHeader
									collapsed={collapsed}
									setCollapsed={setCollapsed}
								/>
								<MainContent />
								<MainFooter />
							</Layout>
						</Layout>
						<Tour
							open={rootStore.tourStore.open}
							onClose={() => rootStore.tourStore.resetTour()}
							steps={rootStore.tourStore.steps}
						/>
					</AppDesign>
				</MobXProvider>
			</Suspense>
		</BrowserRouter>
	)
}

export default observer(App)
