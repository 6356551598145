import { makeObservable, observable } from 'mobx'
import { LstItmBase } from './lst-itm-base'

/**
 * Class representing a Sprint item, extending the base list item class LstItmBase.
 * Contains additional properties and methods specific to Sprint items.
 *
 * @class Sprint
 * @extends {LstItmBase}
 */
export class Sprint extends LstItmBase {
	sprint_id

	/**
	 * Creates an instance of Sprint.
	 * @param {string} id - The unique identifier for the Sprint item.
	 */
	constructor(id) {
		super(id)
		this.sprint_id = id

		makeObservable(this, {
			sprint_id: observable
		})
	}
}
