import { addGETParams } from '../config'
import ax from './api'

export const uploadImage = async (formData) => {
	try {
		const response = await ax.post('/images/upload-image.php', formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
		return response // Ensure this returns the whole response object
	} catch (error) {
		throw new Error(`uploadImage() Upload failed: ${error.message}`)
	}
}
// not used yet
export const getImageUrl = async (params) => {
	const _url = addGETParams(`/images/get-image.php`, params)

	const { data } = await ax.get(_url)
	return data
}
