import React, { useEffect } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { LogoutOutlined } from '@ant-design/icons'
import { useStores } from '../../stores/MobXProvider'
import { routesMenu } from '../../pages/routes'

const NavBar = () => {
	const { users, appType } = useStores()
	const { appIsClient, appIsTeam } = appType

	const items = []

	routesMenu.forEach((route) => {
		if (!route.label) return
		if (route.loggedInDisable && users.isLoggedIn) return
		if (route.loggedInActive && !users.isLoggedIn) return
		if (route.appType && route.appType === 'client' && !appIsClient) return
		if (route.appType && route.appType === 'team' && !appIsTeam) return
		if (route.published === false) return

		items.push({
			key: route.key,
			label: <Link to={route.path}>{route.label}</Link>,
			icon: route?.icon ? <route.icon /> : null
		})
	})

	// if logged in let's add logout with onClick logout()
	if (users.isLoggedIn) {
		items.push({
			key: '/logout',
			label: (
				<Link
					to="/login" // Path required only if we want to redirect to other page
					onClick={() => users.logout()}
					style={{
						color: '#bdc2c6'
					}}
				>
					<LogoutOutlined />
				</Link>
			)
		})
	}

	const curentSelectedMenuItem = () => {
		const path = window.location.pathname
		const route = routesMenu.find((route) => route.path === path)
		return route ? route.path : '/'
	}

	const [selectedMenuItem, setSelectedMenuItem] = React.useState(
		curentSelectedMenuItem()
	)

	const curentPath = window.location.pathname.split('/')[1]
	useEffect(() => {
		setSelectedMenuItem(curentSelectedMenuItem())
	}, [curentPath])

	return (
		<Menu
			mode="horizontal"
			theme="Light"
			style={{
				background: 'transparent',
				width: 'auto',
				textAlign: 'right',
				alignItems: 'center',
				justifyContent: 'flex-end',
				color: '#000'
			}}
			onClick={(e) => setSelectedMenuItem(e.key)}
			selectedKeys={[selectedMenuItem]}
			items={items}
			ref={React.createRef()}
		/>
	)
}

export default observer(NavBar)
