import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { Space, Typography } from 'antd'
import { DO_LOAD } from '../../config'
import { useStores } from '../../stores/MobXProvider'
import { actLoadUser } from '../../stores/users/actions'
import { LstTable } from '../../components/Shared'

const { Title } = Typography

/*
 * @LstItem
 * List user email
 */
const LstItem = observer(({ id }) => {
	const { users } = useStores()
	const user = users.requireUserById(id)
	console.log('--- LstItem', user)
	useEffect(() => {
		// Fetch only if user is empty
		if (user.loaded === DO_LOAD) {
			actLoadUser(users, id, false)
				.catch(console.error)
				.finally(() => {
					users.saveState()
				})
		}
	}, []) // Depend only on userId

	return (
		<tr>
			<td>
				<Link to={`${id}/data`}>{user.email}</Link>
			</td>
		</tr>
	)
})

LstItem.propTypes = {
	id: PropTypes.string.isRequired
}

const UsersLst = observer(() => {
	const { users } = useStores()
	const { loggedInUser } = users
	const fullIds = new Set([...loggedInUser.clients, ...loggedInUser.team])
	const ids = Array.from(fullIds)
	return (
		<Space direction="vertical" style={{ width: '100%', padding: 20 }}>
			<Title
				level={1}
				style={{ fontSize: 25, marginTop: 0, marginBottom: 10 }}
			>
				Users List
			</Title>

			<LstTable className="table-pro" LstItem={LstItem} store={{ ids }}>
				<tr>
					<th>Name</th>
				</tr>
			</LstTable>
		</Space>
	)
})

export default UsersLst
