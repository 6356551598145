import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'

const MobXProviderContext = createContext(null)

/**
 * @component MobXProvider
 * @description Provides the MobX root store to the component tree.
 * @param {object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components that will have access to the MobX store.
 * @param {object} props.rootStore - The root store to be provided to the component tree.
 * @returns {JSX.Element} A context provider with the root store.
 */
export const MobXProvider = ({ children, rootStore }) => {
	return (
		<MobXProviderContext.Provider value={rootStore}>
			{children}
		</MobXProviderContext.Provider>
	)
}

MobXProvider.propTypes = {
	children: PropTypes.node.isRequired,
	rootStore: PropTypes.object.isRequired
}

/**
 * @function useStores
 * @description Hook to use the MobX stores provided by the MobXProvider.
 * @returns {object} The MobX root store.
 */
export const useStores = () => useContext(MobXProviderContext)
