import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function TestError() {
  throw new Error("A custom error message.");
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
    console.error("Error:", error);
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <div className="section-error-bounderies">
          <h2 className="header">Ups...</h2>
          <p className="text">
            Unexpected error occurred.
            <br></br>
            Administrator was informed.
          </p>
          <Link className="btn-style-1" to="/">
            Home
          </Link>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export { ErrorBoundary };
