import { getModifiedItm } from '../modules/api-item'
import { responseHasError } from './users/actions'
import { doneLoading, startError, startLoading } from './work-progress/actions'

export const isModifiedItm = async (modified, targetLst, params) => {
	try {
		const result = await getModifiedItm(targetLst, params)

		// result.modified and modified, must be defined and not null
		if (!result?.modified || !modified) return false

		if (result.modified !== modified) {
			return true
		}
	} catch (err) {
		console.error('isModifiedItm error:', err)
	}
	return false
}
export const wrapWithLoading =
	(fn, options = {}, rethrow = false) =>
	async (...args) => {
		let _progressId

		try {
			// Start the loading process
			_progressId = startLoading(options.loadingMessage || 'Loading...')

			// Execute the wrapped function
			const result = await fn(...args)

			// Return the result if no error occurred
			return result
		} catch (err) {
			!rethrow && console.error('wrapWithLoading error:', err)

			// Execute specific error actions
			responseHasError(err)

			// Handle specific authorization error
			if (err.status === 'error') {
				startError(err.message)
			} else {
				startError('An unexpected error occurred')
			}

			// Re-throw the error to ensure the calling code is aware of the failure
			if (rethrow) throw err
		} finally {
			// Ensure that doneLoading is called even if the wrapped function or error handling fails
			if (_progressId) {
				doneLoading(_progressId)
			}
		}
	}
