/* eslint-disable react/prop-types */
import { observer } from 'mobx-react-lite'
import React from 'react'
import PropTypes from 'prop-types'
import _store from '../../stores'
import {
	doneError,
	doneLoading,
	donePushing
} from '../../stores/work-progress/actions'
import { Button, Card, Col, Row, Space, Spin } from 'antd'
import { CloseOutlined, CloseCircleOutlined } from '@ant-design/icons'

const Notification = ({ id, isLoading, description, onClose, isType }) => {
	const color =
		isType === 'error' ? 'red' : isType === 'success' ? 'green' : null

	return (
		<Card
			size="small"
			// extra={onClose && <CloseOutlined onClick={() => onClose(id)} />}
			style={{
				width: 350,
				background: '#ffffffbf',
				border: color ? `1px solid ${color}` : 'unset'
			}}
			styles={{
				header: {
					border: 'unset',
					display: 'none'
				},
				body: {
					padding: '8px 12px'
				}
			}}
		>
			<Row
				gutter={5}
				align="middle"
				style={{ justifyContent: 'space-between' }}
			>
				<Col span={22}>
					<Row
						gutter={5}
						align="middle"
						style={{ justifyContent: 'space-between' }}
					>
						{isType === 'error' && (
							<Col span={2}>
								<CloseCircleOutlined style={{ color }} />
							</Col>
						)}
						{isLoading && (
							<Col span={2}>
								<Spin size="small" />
							</Col>
						)}
						<Col span={isLoading ? 20 : 22}>
							<p style={{ lineHeight: 1.2, margin: 0 }}>
								{description}
							</p>
						</Col>
					</Row>
				</Col>
				<Col span={2}>
					{onClose && <CloseOutlined onClick={() => onClose(id)} />}
				</Col>
			</Row>
		</Card>
	)
}

Notification.propTypes = {
	id: PropTypes.string.isRequired,
	description: PropTypes.string,
	onClose: PropTypes.func,
	isType: PropTypes.string
}

export const Notifications = observer(() => {
	const { loadingTasks, pushingTasks, errors } = _store.workProgress

	const removeNotification = (id) => {
		if (loadingTasks[id]) {
			doneLoading(id)
		} else if (pushingTasks[id]) {
			donePushing(id)
		} else if (errors[id]) {
			doneError(id)
		}
	}

	const allTasks = { ...loadingTasks, ...pushingTasks, ...errors }
	const countTasks = Object.keys(allTasks).length
	if (countTasks === 0) {
		return null
	}
	return (
		<div className="notifications-container">
			<Space
				direction="vertical"
				style={{
					height: '100%',
					justifyContent: 'flex-end'
				}}
			>
				{Object.values(allTasks).map((task) => (
					<Notification
						key={task.id}
						id={task.id}
						isLoading={loadingTasks[task.id]}
						description={task.ti}
						onClose={removeNotification}
						isType={task?.type || 'default'}
					/>
				))}
				{countTasks > 3 && (
					<Button
						style={{
							width: '100%'
						}}
						type="primary"
						onClick={() =>
							Object.keys(allTasks).forEach(removeNotification)
						}
					>
						Clear all
					</Button>
				)}
			</Space>
		</div>
	)
})
