import { get } from 'lodash'
import { runInAction } from 'mobx'
import { requireSprintById } from '../../sprints/actions'
import { getCompactLst } from '../../../modules/api-compact'
import { PLAN_FOR_DELETION_NR_OF_DAYS, warnPt } from '../../../config'
import rootStore from '../..'
import { responseHasError } from '../../users/actions'
import { deleteLstItem } from '../../../modules/api-delete'
import dayjs from 'dayjs'
import { wrapWithLoading } from '../../general-actions'

const uiLstSprintsStore = rootStore.uiLstSprints

/**
 * @async
 * @function actLoadSprints
 * @description Asynchronously loads sprints from the server and updates the UI list store.
 * @param { function } navigate - The navigation function.
 * @returns {Promise<void>}
 */
export const actLoadSprints = wrapWithLoading(
	async (get_modified = false) => {
		const params = {
			del: uiLstSprintsStore.del,
			pg: uiLstSprintsStore.pg,
			rpg: uiLstSprintsStore.rpg,
			search: uiLstSprintsStore.search,
			category_id: uiLstSprintsStore.filter?.category || '',
			progress: uiLstSprintsStore.filter?.progress || '',
			get_modified: get_modified
		}

		const _response = await getCompactLst('sprints', params)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success' && !get_modified) {
			runInAction(() => {
				_actSync(
					get(_response, ['itms'], []),
					get(_response, ['nr'], 0)
				)
			})
		} else if (
			!hasError &&
			_response.status === 'success' &&
			get_modified
		) {
			return _actCheckIfListIsModified(
				get(_response, ['itms'], []),
				get(_response, ['nr'], 0)
			)
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Loading Sprints' }
)

export const actUndoOrDeleteSprint = wrapWithLoading(
	async (sprintId, status = true) => {
		const plan_for_deletion = dayjs()
			.add(PLAN_FOR_DELETION_NR_OF_DAYS['sprints'], 'day')
			.unix()
		const timestamp = status ? plan_for_deletion : false

		const _response = await deleteLstItem('sprints', {
			id: sprintId,
			to_delete: timestamp || false
		})
		const _storeItm = requireSprintById(sprintId)
		_storeItm.setToDelete(timestamp)

		const hasError = responseHasError(_response)
		if (!hasError && _response.status === 'success') {
			runInAction(() => {
				_storeItm.setToDelete(timestamp || false)

				uiLstSprintsStore.setIds(
					uiLstSprintsStore.ids.filter((id) => id !== sprintId)
				)
				uiLstSprintsStore.setNr(uiLstSprintsStore.nr - 1)
			})
		} else {
			throw _response
		}
	},
	{ loadingMessage: 'Del/Undo Sprint' }
)

const _actCheckIfListIsModified = (srvItms, nr) => {
	const listIsModified = uiLstSprintsStore.checkIfListIsModified(
		srvItms.map((it) => it.sprint_id),
		nr
	)
	if (listIsModified) {
		return true
	}

	for (const _srvItm of srvItms) {
		const _storeItm = requireSprintById(_srvItm.sprint_id)
		if (_storeItm.modified !== _srvItm.modified) {
			return true
		}
	}

	return false
}

/**
 * @function _actSync
 * @description Syncs the server items with the store items.
 * @param {Array} srvItms - Server items.
 * @param {number} nr - Number of items.
 */
const _actSync = (srvItms, nr) => {
	warnPt(srvItms, nr)
	runInAction(() => {
		for (const _srvItm of srvItms) {
			const _storeItm = requireSprintById(_srvItm.sprint_id)
			_storeItm.updateFromServer(_srvItm)
		}
		uiLstSprintsStore.setIds(srvItms.map((it) => it.sprint_id))
		uiLstSprintsStore.setNr(nr)
	})
}
