import React from 'react'
import '../../App.css'
import { Layout } from 'antd'
import { observer } from 'mobx-react-lite'
import { footerStyle } from '../../design/app-style-config'

const { Footer } = Layout

const MainFooter = observer(() => {
	return (
		<Footer style={footerStyle}>
			webackit©{new Date().getFullYear()} Created by Recu Daniel
		</Footer>
	)
})

export default MainFooter
