import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStores } from '../../stores/MobXProvider'
import PropTypes from 'prop-types'

const AssignPlatformsInput = observer(
	({ user_id, value, onChange, ...props }) => {
		const { users } = useStores()
		const user = users.requireUserById(user_id)

		const options = user.platforms
			.filter((platform) => platform.status === 'active')
			.map((platform) => ({
				label: platform.name,
				value: platform.id
			}))
		return (
			<Select
				options={options}
				value={value}
				onChange={onChange}
				mode="multiple"
				allowClear
				placeholder="Assign platforms"
				style={{
					width: '100%'
				}}
				{...props}
			/>
		)
	}
)

AssignPlatformsInput.propTypes = {
	user_id: PropTypes.string.isRequired,
	value: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
}

export default AssignPlatformsInput
