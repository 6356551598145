import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Routes, Route, useNavigate } from 'react-router-dom'

import RequestsLst from './RequestsLst'
import { useStores } from '../../stores/MobXProvider'

const RequestsMain = () => {
	const navigate = useNavigate()

	const { users } = useStores()
	const { loggedInUserId } = users
	useEffect(() => {
		if (!users.isLoggedIn) {
			navigate('/logout')
			return
		}
	}, [loggedInUserId])

	return (
		<Routes>
			<Route path="/" element={<RequestsLst />} />
			<Route path="*" element={<div>Not found</div>} />
		</Routes>
	)
}
export default observer(RequestsMain)
