import { makeObservable, observable, computed, action } from 'mobx'

export class LstTypeBase {
	list = []
	ItemClass = null

	constructor(ItemClass, list = []) {
		// ItemClass is a class that will be used to create new items in the list
		this.ItemClass = ItemClass

		// list is an array of objects that will be used to create new items in the list
		this.list = list.map((itm) => new ItemClass(itm))

		makeObservable(this, {
			list: observable,
			updateItem: action.bound,
			addItem: action.bound,
			deleteItem: action.bound,
			getItemById: action.bound,
			itemsCount: computed
		})
	}

	prepareForSave() {
		if (!Array.isArray(this.list)) {
			return [] // Ensure the function returns an empty array if this.list is not an array
		}

		return this.list.map((item) => {
			if (!item.comments) {
				return item
			}
			// Ensure item.comments.list is an array before mapping
			const comments = Array.isArray(item.comments.list)
				? item.comments.list.map((comment) => comment)
				: [] // Return an empty array if item.comments.list is not an array

			return {
				...item,
				comments
			}
		})
	}

	updateItem(item) {
		const index = this.list.findIndex((s) => s.id === item.id)
		if (index >= 0) {
			this.list[index] = item
		} else {
			this.addItem(item)
		}
	}

	addItem(data = {}) {
		const item = new this.ItemClass(data)
		this.list.push(item)
	}

	deleteItem(itemId) {
		this.list = this.list.filter((item) => item.id !== itemId)
	}

	getItemById(itemId) {
		return this.list.find((item) => item.id === itemId)
	}

	get itemsCount() {
		return this.list.length
	}
}
