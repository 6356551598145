import { addGETParams } from '../config'
import ax from './api'

/*
 * getCompactLst - Get a compact list of items from the server, such as sprints, categories, etc.
 * @param {string} targetLst - The target list to get from the server
 * @param {string} controlerId - Id of the user that has permission to view or controll specific list items
 * @param {object} params - Additional parameters to send to the server
 */
export const getCompactLst = async (targetLst, params) => {
	const _url = addGETParams(`/compact/get.php?lst=${targetLst}`, params)

	const { data } = await ax.get(_url)
	return data
}
